export const postMessageToParent = (msgCode, msgData) => {
	window.parent.postMessage(
		{
			msgCode: msgCode,
			msgData: msgData,
		},
		'*',
	)
}

export const postMessageWithNoDataToParent = (msgCode) => {
	window.parent.postMessage(msgCode, '*')
}
