import { ROUTER_PROVIDER, SPORT_ID, SPORT_NAME } from 'constants'
import { set, isNil } from 'lodash'
import { compile, match, pathToRegexp } from 'path-to-regexp'
import { matchPath } from 'react-router'
import { matchRoutes } from 'react-router-dom'
import { FAVOURITES_TYPE } from '../../constants/leftMenu'
import { languages } from 'constants/i18n'
import {
	routesAsian,
	routesStandardEsportsHub,
	routesEsportsHub,
	routesStandard,
	routesUnifiedAccount,
	navIgnoreRoutesStandardMap,
} from '../Routes/routes'
import { MEMBER_SERVICES, SPORTS_SERVICES } from '../../constants'

/**
 * This function is used to get the current router provider based on the root path of the current URL.
 *
 * @returns {Object} An object containing the following properties:
 * - name: The name of the router provider.
 * - routes: The routes associated with the router provider.
 * - defaultSportCode: The default sport code associated with the router provider.
 */
export const getCurrentRouterProvider = () => {
	const currentPath = window?.location?.pathname || ''
	const rootPaths = currentPath.split('/')
	const pathOne = rootPaths[2]
	const pathTwo = rootPaths[3]

	if (
		pathOne === ROUTER_PROVIDER.ESPORTS_HUB &&
		window.env?.isEnableEsportsHub
	) {
		return {
			name: ROUTER_PROVIDER.ESPORTS_HUB,
			defaultSportCode: '',
			favouriteViewParam: 'ESPORTS_HUB',
			apiEndpoint: {
				getFavourite: '',
				updateFavourite: '',
			},
			mobilePath: '/esports-hub/esports',
			routes: {
				base: routesStandardEsportsHub.base,
				home: '',
				sportLeagues: '',
				esportGameLeague: '',
				esportGameLeagueEvent: '',
			},
		}
	}

	if (
		`${pathOne}/${pathTwo}` === ROUTER_PROVIDER.STANDARD_ESPORTS_HUB &&
		window.env?.enableIsOnlyEsports
	) {
		return {
			name: ROUTER_PROVIDER.STANDARD_ESPORTS_HUB,
			defaultSportCode: SPORT_ID[SPORT_NAME.esports],
			apiEndpoint: {
				getFavourite: SPORTS_SERVICES.GET_STANDARD_ESPORTS_HUB_FAVOURITE,
				updateFavourite: MEMBER_SERVICES.UPDATE_STANDARD_ESPORTS_HUB_FAVOURITE,
			},
			mobilePath: '/esports-hub/esports',
			routes: {
				...routesStandardEsportsHub,
				esportGame: routesStandardEsportsHub.game,
				esportGameLeague: routesStandardEsportsHub.gameLeague,
				esportGameLeagueEvent: routesStandardEsportsHub.event,
				esportHighlights: routesStandardEsportsHub.highlights,
				sport: routesStandardEsportsHub.games,
				sportHighlights: routesStandardEsportsHub.highlights,
				sportLeague: routesStandardEsportsHub.gameLeague,
				sportLeagues: routesStandardEsportsHub.leagues,
				sportGames: routesStandardEsportsHub.gameLeague,
				sportLive: routesStandardEsportsHub.live,
			},
		}
	}

	return {
		name: ROUTER_PROVIDER.STANDARD,
		defaultSportCode: '',
		apiEndpoint: {
			getFavourite: SPORTS_SERVICES.GET_STANDARD_FAVOURITE,
			updateFavourite: MEMBER_SERVICES.UPDATE_STANDARD_FAVOURITE,
		},
		mobilePath: '',
		routes: {
			...routesStandard,
		},
		navIgnoreRoutes: navIgnoreRoutesStandardMap,
	}
}

/**
 *
 * @returns {`wss://${string}/sports-websocket/ws?view=euro`}
 */
export const buildWebSocketURL = (token = '', ulp = '') => {
	if (token && ulp) {
		return `wss://${window.location.hostname}/sports-websocket/ws?token=${token}&ulp=${ulp}&view=euro`
	}
	return `wss://${window.location.hostname}/sports-websocket/ws?view=euro`
}

/**
 *
 * @param {number} sportId
 * @param {boolean} isHighLight
 * @returns {string}
 */
export const buildLeftMenuSportsURL = (sportId, isHighLight) => {
	if (typeof sportId !== 'number') {
		return ''
	}
	const sportCode = SPORT_ID[sportId]
	if (isHighLight) {
		return buildRouteUrl(routesStandard.sportHighlights, { sportCode }, true)
	}
	return buildRouteUrl(routesStandard.sport, { sportCode }, true)
}

export const getTabConfig = () => {
	const { name } = getCurrentRouterProvider()
	switch (name) {
		case ROUTER_PROVIDER.STANDARD_ESPORTS_HUB: {
			return {
				highlights: {
					name: 'app.banner.highlights',
					routes: routesStandardEsportsHub.highlights,
				},
				live: {
					name: 'text.live',
					routes: routesStandardEsportsHub.live,
				},
				games: {
					name: 'app.banner.games',
					routes: routesStandardEsportsHub.games,
				},
				leagues: {
					name: 'app.banner.leagues',
					routes: routesStandardEsportsHub.leagues,
				},
				futures: {
					name: 'app.banner.futures',
					routes: routesStandardEsportsHub.futures,
				},
				matchupsGamesName: {
					name: 'app.banner.matchups',
					routes: routesStandardEsportsHub.game,
				},
				gamesNameLive: {
					name: 'text.live',
					routes: routesStandardEsportsHub.gameLive,
				},
				gamesNameLeagues: {
					name: 'app.banner.leagues',
					routes: routesStandardEsportsHub.gameLeagues,
				},
			}
		}
		case ROUTER_PROVIDER.STANDARD:
		default: {
			return {
				matchups: {
					name: 'app.banner.matchups',
					routes: routesStandard.sport,
				},
				matchupsGamesName: {
					name: 'app.banner.matchups',
					routes: routesStandard.esportGame,
				},
				live: {
					name: 'text.live',
					routes: routesStandard.sportLive,
				},
				gamesNameLive: {
					name: 'text.live',
					routes: routesStandard.esportGameLive,
				},
				leagues: {
					name: 'app.banner.leagues',
					routes: routesStandard.sportLeagues,
				},
				gamesNameLeagues: {
					name: 'app.banner.leagues',
					routes: routesStandard.esportGameLeagues,
				},
				teasers: {
					name: 'app.banner.teasers',
					routes: routesStandard.sportTeasers,
				},
				futures: {
					name: 'app.banner.futures',
					routes: routesStandard.sportFutures,
				},
				games: {
					name: 'app.banner.games',
					routes: routesStandard.sportGames,
				},
				highlights: {
					name: 'app.banner.highlights',
					routes: routesStandard.sportHighlights,
				},
				otIncluded: {
					name: 'app.banner.ot_included',
					routes: routesStandard.sport,
				},
				regulationTime: {
					name: 'app.banner.regulation_time',
					routes: routesStandard.sportRegulation,
				},
			}
		}
	}
}

/**
 *
 * @param {number} sportId
 * @param lang language
 * @returns {{name: string, url: string}[]}
 */
export const buildTabMenuSportURLs = (sportId, lang) => {
	if (!sportId) {
		return []
	}
	const tabConfig = getTabConfig()
	let tabList = []

	switch (sportId) {
		case SPORT_NAME.soccer: {
			tabList = [
				tabConfig.highlights,
				tabConfig.live,
				tabConfig.leagues,
				tabConfig.futures,
			]
			break
		}
		case SPORT_NAME.esports: {
			tabList = [
				tabConfig.highlights,
				tabConfig.live,
				tabConfig.games,
				tabConfig.leagues,
				tabConfig.futures,
			]
			break
		}
		case SPORT_NAME.hockey: {
			tabList = [
				tabConfig.otIncluded,
				tabConfig.regulationTime,
				tabConfig.leagues,
				tabConfig.futures,
			]
			break
		}
		default: {
			tabList = [
				tabConfig.matchups,
				tabConfig.live,
				tabConfig.leagues,
				tabConfig.futures,
			]
			break
		}
	}

	const sportCode = SPORT_ID[sportId]
	return tabList.map((item) => {
		return {
			name: item.name,
			url: buildRouteUrl(item.routes, { sportCode, lang }),
		}
	})
}

/**
 *
 * @param gameCode Esport game name
 * @param lang language
 * @returns {{name: *, url: string}[]}
 */
export const buildTabMenuGamesNameURLs = (gameCode, lang) => {
	const tabConfig = getTabConfig()
	const tabList = [
		tabConfig.matchupsGamesName,
		tabConfig.gamesNameLive,
		tabConfig.games,
		tabConfig.gamesNameLeagues,
	]
	const sportCode = SPORT_ID[SPORT_NAME.esports]
	return tabList.map((item) => {
		return {
			name: item.name,
			url: buildRouteUrl(item.routes || '', { sportCode, gameCode, lang }),
		}
	})
}

/**
 *
 * @param {string} sportCode
 * @param {string} sportName
 * @param {string} gameCode
 * @param {string} gameName
 * @param {string} leagueCode
 * @param {string} leagueName
 * @param {string} eventName
 * @param {string} eventId
 * @param {string} lang
 * @param {Object} homeRoute
 * @param {Object} routes
 * @returns {[{name: string, urlPath: string}]}
 */
export const buildBreadcrumbOddBanner = ({
	sportCode,
	sportName,
	gameCode,
	gameName,
	leagueCode,
	leagueName,
	eventName,
	eventId,
	lang,
	homeRoute = {
		name: 'app.banner.home',
		urlPath: '',
	},
	routes,
}) => {
	const result = [
		{
			...homeRoute,
			urlPath: buildRouteUrl(routes.home, {}, true),
		},
	]

	const routerSport = [
		SPORT_ID[SPORT_NAME.soccer],
		SPORT_ID[SPORT_NAME.esports],
	].find((code) => code === sportCode)
		? routes.sportHighlights
		: routes.sport

	if (sportCode) {
		result.push({
			name: sportName,
			urlPath: buildRouteUrl(routerSport, { sportCode, lang }),
		})
	}

	if (gameCode) {
		result.push({
			name: gameName,
			urlPath: buildRouteUrl(routes.esportGame, { gameCode, lang }),
		})
	}
	if (gameCode && leagueCode) {
		result.push({
			name: leagueName,
			urlPath: buildRouteUrl(routes.esportGameLeague, {
				gameCode,
				leagueCode,
				lang,
			}),
		})
	}
	if (gameCode && eventName) {
		result.push({
			name: eventName,
			urlPath: buildRouteUrl(routes.esportGameLeagueEvent, {
				sportCode,
				gameCode,
				leagueCode,
				eventName,
				eventId,
				lang,
			}),
		})
	}

	if (!gameCode && leagueCode) {
		result.push({
			name: leagueName,
			urlPath: buildRouteUrl(routes.sportLeague, {
				sportCode,
				leagueCode,
				lang,
			}),
		})
	}
	if (!gameCode && eventName) {
		result.push({
			name: eventName,
			urlPath: buildRouteUrl(routes.sportLeagueEvent, {
				sportCode,
				leagueCode,
				eventName,
				eventId,
				lang,
			}),
		})
	}
	result[result.length - 1].urlPath = ''
	return result
}

/**
 *
 * @param {string} sportCode
 * @param {string} themeName
 * @returns {string|'/standardst/img/headers/sports/single/generic.jpg'}
 */
export const getSportImageBackgroundURL = (sportCode, themeName) => {
	switch (themeName) {
		case 'maverickGames': {
			const baseUrl = '/standardst/img/headers/sports/maverickGames'
			const map = {
				[SPORT_ID[SPORT_NAME.baseball]]: `${baseUrl}/baseball.jpg`,
				[SPORT_ID[SPORT_NAME.basketball]]: `${baseUrl}/basketball.jpg`,
				[SPORT_ID[SPORT_NAME.boxing]]: `${baseUrl}/boxing.jpg`,
				[SPORT_ID[SPORT_NAME.cricket]]: `${baseUrl}/cricket.jpg`,
				[SPORT_ID[SPORT_NAME.cycling]]: `${baseUrl}/cycling.jpg`,
				[SPORT_ID[SPORT_NAME.esports]]: `${baseUrl}/esports.jpg`,
				[SPORT_ID[SPORT_NAME.football]]: `${baseUrl}/football.jpg`,
				[SPORT_ID[SPORT_NAME.futsal]]: `${baseUrl}/futsal.jpg`,
				[SPORT_ID[SPORT_NAME.golf]]: `${baseUrl}/golf.jpg`,
				[SPORT_ID[SPORT_NAME.hockey]]: `${baseUrl}/hockey.jpg`,
				[SPORT_ID[SPORT_NAME.mma]]: `${baseUrl}/mma.jpg`,
				[SPORT_ID[SPORT_NAME['rugby-league']]]: `${baseUrl}/rugby.jpg`,
				[SPORT_ID[SPORT_NAME['rugby-union']]]: `${baseUrl}/rugby.jpg`,
				[SPORT_ID[SPORT_NAME.snooker]]: `${baseUrl}/snooker.jpg`,
				[SPORT_ID[SPORT_NAME.soccer]]: `${baseUrl}/soccer.jpg`,
				[SPORT_ID[SPORT_NAME.tennis]]: `${baseUrl}/tennis.jpg`,
				[SPORT_ID[SPORT_NAME.volleyball]]: `${baseUrl}/volleyball.jpg`,
			}
			return map[sportCode] || `${baseUrl}/sports_banner_default.jpg`
		}
		default: {
			const baseUrl = '/standardst/img/headers/sports/single'
			const map = {
				[SPORT_ID[SPORT_NAME.baseball]]: `${baseUrl}/baseball.jpg`,
				[SPORT_ID[SPORT_NAME.basketball]]: `${baseUrl}/basketball.jpg`,
				[SPORT_ID[SPORT_NAME.boxing]]: `${baseUrl}/boxing.jpg`,
				[SPORT_ID[SPORT_NAME.cricket]]: `${baseUrl}/cricket.jpg`,
				[SPORT_ID[SPORT_NAME.cycling]]: `${baseUrl}/cycling.jpg`,
				[SPORT_ID[SPORT_NAME.esports]]: `${baseUrl}/esports.jpg`,
				[SPORT_ID[SPORT_NAME.football]]: `${baseUrl}/football.jpg`,
				[SPORT_ID[SPORT_NAME.golf]]: `${baseUrl}/golf.jpg`,
				[SPORT_ID[SPORT_NAME.hockey]]: `${baseUrl}/hockey.jpg`,
				[SPORT_ID[SPORT_NAME.mma]]: `${baseUrl}/mma.jpg`,
				[SPORT_ID[SPORT_NAME['rugby-league']]]: `${baseUrl}/rugby.jpg`,
				[SPORT_ID[SPORT_NAME['rugby-union']]]: `${baseUrl}/rugby.jpg`,
				[SPORT_ID[SPORT_NAME.soccer]]: `${baseUrl}/soccer.jpg`,
				[SPORT_ID[SPORT_NAME.tennis]]: `${baseUrl}/tennis.jpg`,
			}
			return map[sportCode] || `${baseUrl}/generic.jpg`
		}
	}
}

/**
 *
 * @param sportCode
 * @param {string} themeName
 * @returns {string|'/standardst/img/headers/matchup/generic.jpg'}
 */
export const getEventDetailImageBackgroundURL = (sportCode, themeName) => {
	switch (themeName) {
		case 'maverickGames': {
			const baseUrl = '/standardst/img/headers/matchup/maverickGames'
			const map = {
				[SPORT_ID[SPORT_NAME.football]]: `${baseUrl}/amer_football.png`,
				[SPORT_ID[SPORT_NAME.baseball]]: `${baseUrl}/baseball.png`,
				[SPORT_ID[SPORT_NAME.basketball]]: `${baseUrl}/basketball.png`,
				[SPORT_ID[SPORT_NAME.boxing]]: `${baseUrl}/boxing.png`,
				[SPORT_ID[SPORT_NAME.cricket]]: `${baseUrl}/cricket.png`,
				[SPORT_ID[SPORT_NAME.esports]]: `${baseUrl}/esports.png`,
				[SPORT_ID[SPORT_NAME.futsal]]: `${baseUrl}/futsal.png`,
				[SPORT_ID[SPORT_NAME.golf]]: `${baseUrl}/golf.png`,
				[SPORT_ID[SPORT_NAME.hockey]]: `${baseUrl}/hockey.png`,
				[SPORT_ID[SPORT_NAME.mma]]: `${baseUrl}/mma.png`,
				[SPORT_ID[SPORT_NAME['rugby-league']]]: `${baseUrl}/rugby.jpg`,
				[SPORT_ID[SPORT_NAME['rugby-union']]]: `${baseUrl}/rugby.jpg`,
				[SPORT_ID[SPORT_NAME.snooker]]: `${baseUrl}/snooker.png`,
				[SPORT_ID[SPORT_NAME.soccer]]: `${baseUrl}/soccer.png`,
				[SPORT_ID[SPORT_NAME.tennis]]: `${baseUrl}/tennis.png`,
				[SPORT_ID[SPORT_NAME.volleyball]]: `${baseUrl}/volleyball.png`,
			}
			return map[sportCode] || `${baseUrl}/generic.png`
		}
		default: {
			const baseUrl = '/standardst/img/headers/matchup'
			const map = {
				[SPORT_ID[SPORT_NAME.baseball]]: `${baseUrl}/baseball.png`,
				[SPORT_ID[SPORT_NAME.basketball]]: `${baseUrl}/basketball.png`,
				[SPORT_ID[SPORT_NAME.boxing]]: `${baseUrl}/boxing.png`,
				[SPORT_ID[SPORT_NAME.esports]]: `${baseUrl}/esports.png`,
				[SPORT_ID[SPORT_NAME.football]]: `${baseUrl}/football.png`,
				[SPORT_ID[SPORT_NAME.hockey]]: `${baseUrl}/hockey.png`,
				[SPORT_ID[SPORT_NAME.soccer]]: `${baseUrl}/soccer.png`,
				[SPORT_ID[SPORT_NAME.tennis]]: `${baseUrl}/tennis.png`,
			}
			return map[sportCode] || `${baseUrl}/generic.png`
		}
	}
}

export const getTriangleImgURL = (themeName) => {
	const baseUrl = '/standardst/img/headers/matchup'
	switch (themeName) {
		case 'maverickGames': {
			return `${baseUrl}/maverickGames/triangle.svg`
		}
		default: {
			return `${baseUrl}/triangle.svg`
		}
	}
}

/**
 *
 * @param {number} sportId
 * @param {number} leagueId
 * @param {string} gameCode
 * @param {string} leagueCode
 * @returns {string}
 */
export const buildLeftMenuLeaguesURL = ({
	sportId,
	leagueId,
	leagueCode,
	gameCode,
}) => {
	switch (getCurrentRouterProvider().name) {
		case ROUTER_PROVIDER.STANDARD_ESPORTS_HUB: {
			return buildRouteUrl(routesStandardEsportsHub.game, { gameCode }, true)
		}

		default: {
			if (typeof sportId !== 'number') {
				return ''
			}
			if (SPORT_NAME.esports === sportId && gameCode) {
				switch (leagueId) {
					case -1: {
						return buildRouteUrl(routesStandard.esportGames, {}, true)
					}
					default: {
						return buildRouteUrl(routesStandard.esportGame, { gameCode }, true)
					}
				}
			}
			if (typeof leagueCode !== 'string') {
				return ''
			}
			const sportCode = SPORT_ID[sportId]
			switch (leagueId) {
				case -1: {
					return buildRouteUrl(routesStandard.sportLeagues, { sportCode }, true)
				}
				default: {
					return buildRouteUrl(
						routesStandard.sportLeague,
						{ sportCode, leagueCode },
						true,
					)
				}
			}
		}
	}
}

/**
 *
 * @param {string} gameCode
 * @returns {string}
 */
export const buildLeftMenuGamesURL = ({ gameCode = '' }) => {
	return buildRouteUrl(routesStandardEsportsHub.game, { gameCode }, true)
}

/**
 *
 * @param {string} path - base of path Ex: :lang/standard/:sportCode
 * @param {object} params - params pass to path Ex: {lang: 'vn', sportCode: 'soccer'}
 * @param {boolean} withCurrentLang - optional to auto apply current lang to pathname
 * @param {string} hash - optional to add hash to pathname
 * @returns {string} - pathname after convented
 */
export const buildRouteUrl = (path, params, withCurrentLang = false, hash) => {
	if (!path) return '#'
	const filterParam = {}
	Object.entries(params).forEach((para) => {
		const [key, value] = para
		set(filterParam, key, encodeURIComponent(value))
	})
	const isValidHash = typeof hash === 'string' && hash?.length > 0
	if (withCurrentLang) {
		const lang = window.location?.pathname?.split('/')[1]
		const compileUrl = compile(path)({
			...filterParam,
			lang: lang || 'en',
		})
		return isValidHash ? `/${compileUrl}#${hash}` : `/${compileUrl}`
	}
	return isValidHash
		? `/${compile(path)(filterParam)}#${hash}`
		: `/${compile(path)(filterParam)}`
}

export const testRoute = (route, path) => {
	const regex = pathToRegexp(route)
	return regex.test(path)
}

export const buildAsianUrl = (path, params, withCurrentLang = false) => {
	const pathname = buildRouteUrl(path, params, withCurrentLang)
	return `${window.location.origin}${pathname}`
}

/**
 *
 * @param {string} path
 * @param {string} url
 * @returns {string}
 */
export const buildNavigationHeaderUrl = (path, url) => {
	const isExternalUrl = path.split('/')[0] === ':url'
	if (isExternalUrl) {
		const newPath = path.replace(':url/', '')
		return url.concat(buildRouteUrl(newPath, {}, true))
	}
	return buildRouteUrl(path, {}, true)
}

/**
 *
 * @param {number} sportId
 * @param {string} leagueCode
 * @param {string} gameCode
 * @returns {string}
 */
export const buildLeaguesURL = (sportId, leagueCode = '', gameCode = '') => {
	const sportCode = SPORT_ID?.[sportId]
	const { routes } = getCurrentRouterProvider()
	if (!sportCode) {
		return ''
	}
	switch (sportId) {
		case SPORT_NAME.esports: {
			if (leagueCode.startsWith(gameCode) && gameCode.length) {
				leagueCode = leagueCode.slice(gameCode?.length + 1)
			}
			return buildRouteUrl(
				routes.esportGameLeague,
				{
					gameCode,
					leagueCode,
				},
				true,
			)
		}
		default: {
			return buildRouteUrl(
				routes.sportLeague,
				{
					sportCode,
					leagueCode,
				},
				true,
			)
		}
	}
}

export const buildOpenBetsLink = (isB2B) => {
	return {
		linkTo: isB2B
			? buildAsianUrl(routesAsian.myBetFull, {}, true)
			: buildRouteUrl(
					routesUnifiedAccount.bettingHistory,
					{ view: 'standard' },
					true,
			  ),
		target: isB2B ? '_blank' : '',
	}
}

export const buildFavoriteLink = ({
	type,
	gameCode,
	sportCode,
	leagueCode,
	name,
}) => {
	const { routes } = getCurrentRouterProvider()
	switch (type) {
		case FAVOURITES_TYPE.GAME: {
			return buildRouteUrl(routes.esportGame, { gameCode }, true)
		}
		case FAVOURITES_TYPE.GAME_LEAGUE:
		case FAVOURITES_TYPE.GAME_SPECIAL_LEAGUE: {
			return buildRouteUrl(
				routes.esportGameLeague,
				{
					gameCode,
					leagueCode: leagueCode.replace(`${gameCode}-`, ''),
				},
				true,
			)
		}
		case FAVOURITES_TYPE.SPORT_LEAGUE:
		case FAVOURITES_TYPE.SPORT_SPECIAL_LEAGUE: {
			return buildRouteUrl(
				routesStandard.sportLeague,
				{
					sportCode,
					leagueCode,
				},
				true,
			)
		}
		case FAVOURITES_TYPE.PARTICIPANT: {
			return buildRouteUrl(routes.favourites, { name, sportCode }, true)
		}
		default: {
			return ''
		}
	}
}

export const getFirstLang = () => {
	const paths = window.location.pathname?.split('/')?.filter((item) => item)

	const lang = languages.find((l) => l.urlPrefix.default === (paths[0] ?? 'en'))

	return lang?.culture ?? 'en_US'
}

export const isEsportsHubPath = () => {
	return (
		Object.values(routesEsportsHub).find((route) =>
			checkPathnameWithCurrentRoute(route),
		) !== undefined
	)
}

export const isStandardEsportsHubPath = () => {
	return (
		Object.values(routesStandardEsportsHub).find((route) =>
			checkPathnameWithCurrentRoute(route),
		) !== undefined
	)
}

export const isStandardEsportsHubMultiviewPath = () => {
	return !!matchPath(
		{ path: routesStandardEsportsHub.liveCentreMultiview, end: false },
		window.location.pathname,
	)
}

const checkPathnameWithCurrentRoute = (route = '') =>
	matchPath(
		{ path: route.split('#')?.[0], exact: false },
		window.location.pathname,
	)

export const matchesEventDetail = (pathname, params) =>
	(!isNil(params.sportCode) &&
		params.sportCode !== 'esports' &&
		matchRoutes([{ path: routesStandard.sportLeagueEvent }], pathname)) ||
	matchRoutes(
		[
			{ path: routesStandard.esportGameLeagueEvent },
			{ path: routesStandard.sportFutures },
			{ path: routesStandardEsportsHub.event },
		],
		pathname,
	)

export const isInJumpPage = () => {
	const { routes } = getCurrentRouterProvider()
	const matchPattern = match(`/${routes?.jumpPage}`)
	return matchPattern(window.location.pathname)
}
