import {
	BET_LOCATION_TRACKING,
	EVENT_DETAIL_API_ACTION,
	ODD_STATUS,
	MARKET_TAB,
	MARKET_TAB_TYPE,
	PERIOD,
	REGION_CONTAINER,
	RESULTING_UNIT,
	SPORT_ID,
	SPORT_NAME,
	TEAM_TYPE,
} from '../../constants'
import { BetType } from 'apps/components/OddPage/oddPageUlti'
import { cloneDeep, groupBy, isEmpty, isString } from 'lodash'
import { updateObjWithKeys } from '../Common/object'
import isNil from 'lodash/isNil'
import { appIntl } from 'apps/components/LocalizedApp/IntGlobalProvider'
import { getAvailableValues } from '../Common'
import { matchPath } from 'react-router-dom'
import { getCurrentRouterProvider } from '../Url'
import { addBetTracking } from '../Selections'

/**
 *
 * @typedef {Object} InfoResponse
 * @property {number} sportId - ID of the sport
 * @property {string} sportName - Name of the sport
 * @property {string} leagueCode - Code of the league
 * @property {string} leagueName - Name of the league
 * @property {string} resultingUnit - Resulting unit used in some sports like Tennis
 */
/**
 *
 * @typedef {Object} EventDetailResponse
 * @property {InfoResponse} info - Information about the event
 * @property {Object} normal - Normal market data
 * @property {Object} alternateLines - Alternate lines market data
 * @property {Object} bookings - Bookings market data
 * @property {Object} corners - Corners market data
 * @property {Object} gameMarkets - Game market data
 * @property {Object} setMarkets - Set market data
 * @property {Object} matchMarkets - Match market data
 * @property {Object} match180Markets - Match 180 market data
 * @property {Object} kills - Kills market data
 * @property {Object=} update - Update odd object
 * @property {Array} specials - Specials market data
 * @property {number} specialVersion - Version of special data
 * @property {Object=} specialsUpdate - Flag to check if special data is updated
 * @property {number} version - Version of non-special data
 */
/**
 *
 * @typedef {Object} Participant
 * @property {string} name - Name of the participant with translation
 * @property {string} english - Name used when building URL
 * @property {string} type - Type of participant
 */
/**
 *
 * @typedef {Object} FilterTab
 * @property {string} value - value of tab, use as an ID
 * @property {string} keyTranslate - key translation
 * @property {string} defaultMessage - default translation
 * @property {string} hash - hashtag use in URL
 * @property {string} type - tab marker type
 * @property {string} index - index of tab using on MultiviewMarketFilterTab component
 */
/**
 *
 * @typedef {Object} BannerData
 * @property {string} eventId - id of Event Detail
 * @property {string} sportCode - sport code use to build url
 * @property {string} sportId - id of sport
 * @property {string} sportName - sport name show on UI
 * @property {string} leagueId - id of league
 * @property {string} leagueCode - league code use to build url
 * @property {string} leagueName - league name show on UI
 * @property {string=} gameCode - game code use to build url
 * @property {string=} gameName - game name show on UI
 * @property {number|string} time - time start of Event
 * @property {string} eventName - event name
 * @property {string} homeTeam - home team name
 * @property {string} homeTeamCode - home team code using build URL (English only)
 * @property {number} homeTeamScore - home team score
 * @property {number} homeTeamRedCard - home team red cards
 * @property {number} homeTeamYellowCard - home team yellow cards
 * @property {number} homeTeamCorner - home team corner score
 * @property {string} awayTeam - away team name
 * @property {string} awayTeamCode - away team code using build URL (English only)
 * @property {number} awayTeamScore - away team score
 * @property {number} awayTeamRedCard - away team red cards
 * @property {number} awayTeamYellowCard - away team yellow cards
 * @property {number} awayTeamCorner - away team corner score
 * @property {boolean} isLive - flag check if event is live
 * @property {string} rotNum - rotation number of the event
 * @property {string} elapsed - elapsed time of the event
 * @property {string} runningState - running state of the event
 * @property {string} resultingUnit - resulting unit using Tennis or some sport
 * @property {boolean} isSwapHomeAway - flag check swap team name
 */
/**
 *
 * @typedef {Object} TabObj
 * @property {string} value - value of tab, use as an ID
 * @property {string} name - name of tab
 * @property {string} type - type of tab
 * @property {string[]} sectionListIds - list of section ID
 * @property {boolean=} hasSeeAllMarketsButton - flag render SEE MORE button
 * @property {number=} numberSectionShowDefault - number of section show by default in ALL tab
 */
/**
 *
 * @typedef {Object} OddObj
 * @property {string} id - odd id
 * @property {string} parendId - parent event id
 * @property {Object} keysTranslate - object key translate using on name
 * @property {string} defaultMessage - default message show on name if it has not translates
 * @property {string} value - odd value
 * @property {'NORMAL'|'OUTRIGHT'}  type - type bet using when call API
 * @property {string|number} lineId - line ID
 * @property {boolean} isAlt - flag check odd is main line or alt line
 * @property {boolean} offline - flag check odd offline
 * @property {number} teamtype
 * @property {Participant[]} participants - participants of the Event Detail
 * @property {number} awayTeamType index of awayTeam in participants
 * @property {number} homeTeamTypeindex of homeTeam in participants
 * @property {BannerData} bannerData data of banner
 * @property {'NONE'|'UP'|'DOWN'} status value control indicator when odd updated
 * @property {'boolean'} isSpecial flag check outright special
 */
/**
 *
 * @typedef {Object} SectionObj
 * @property {string} id - odd id
 * @property {string=} periodValue
 * @property {string} homeTeamName - odd name
 * @property {string} awayTeamName - odd value
 * @property {number} columns - number of columns in render
 * @property {Object} keysTranslate - object key translate using on name
 * @property {string} defaultMessage - default message show on header
 * @property {boolean} hasHomeAwayHeader - flag check render Home Away header
 * @property {boolean} hasMainLine - flag check render button show more (has main line)
 * @property {string[]} mainLineIds - list of main line odd ID
 * @property {string[]} oddIds -  list of odd ID
 * @property {Participant[]} participants - participants of the Event Detail
 */

const CONFIG_DEFAULT = {
	moneyLine: {
		key: 'moneyLine',
		betTypeValue: BetType?.THE_1X2,
		keyTranslate: 'event_detail.money_line',
		defaultMessage: 'Money Line',
		hasHomeAwayHeader: false,
		hasMainLine: false,
		mainLineIds: [],
		columns: 3,
		order: 0,
	},
	handicap: {
		key: 'handicap',
		betTypeValue: BetType?.HANDICAP,
		keyTranslate: 'event_detail.handicap',
		defaultMessage: 'Handicap',
		hasHomeAwayHeader: true,
		hasMainLine: true,
		mainLineIds: [],
		columns: 2,
		order: 1,
	},
	overUnder: {
		key: 'overUnder',
		betTypeValue: BetType?.OU,
		keyTranslate: 'event_detail.total',
		defaultMessage: 'Total',
		hasHomeAwayHeader: false,
		hasMainLine: true,
		mainLineIds: [],
		columns: 2,
		order: 2,
	},
	teamTotals: {
		key: 'teamTotals',
		betTypeHomeValue: BetType?.HOME_TOTALS,
		betTypeAwayValue: BetType?.AWAY_TOTALS,
		keyTranslate: 'event_detail.team_total',
		defaultMessage: 'Team Total',
		hasHomeAwayHeader: true,
		hasMainLine: false,
		mainLineIds: [],
		columns: 2,
		order: 3,
	},
}

const getConfigBySportId = (sportId) => {
	switch (sportId) {
		case SPORT_NAME.soccer: {
			return { ...CONFIG_DEFAULT }
		}
		case SPORT_NAME.esports:
		case SPORT_NAME.tennis: {
			const result = { ...CONFIG_DEFAULT }
			result.teamTotals.columns = 2
			return result
		}
		default: {
			return CONFIG_DEFAULT
		}
	}
}

export const getTeam = (participants) => {
	const homeTeam = participants?.find((team) => team.type === TEAM_TYPE.HOME)
	const awayTeam = participants?.find((team) => team.type === TEAM_TYPE.AWAY)
	return { homeTeam, awayTeam }
}

export const sumObject = (object) => {
	if (!object) return undefined
	if (typeof object === 'object') {
		return Object.values(object).reduce((total, value) => {
			return total + Number(value)
		}, 0)
	}
	return object
}

const scoreBoardMap = {
	[SPORT_NAME.soccer]: {
		match: {
			id: 'match',
			order: 1,
			isUseIcon: true,
			isSum: true,
			icon: 'icon-soccer',
			title: 'Match',
			value: 0,
		},
		extraTime: {
			id: 'extraTime',
			order: 2,
			isUseIcon: false,
			isSum: false,
			icon: '',
			title: 'ET',
			value: 0,
		},
	},
}

const createScoreBoard = (score, sportId) => {
	if (!score) return undefined
	if (typeof score === 'object') {
		const arrayScore = Object.entries(score)
		const totalScore = arrayScore.reduce((total, [key, value]) => {
			return total + value
		}, 0)
		return arrayScore
			.map(([key, value]) => ({
				...scoreBoardMap?.[sportId]?.[key],
				value: scoreBoardMap?.[sportId]?.[key]?.isSum ? totalScore : value,
			}))
			.sort((a, b) => a.order - b.order)
	}
	return score
}

/**
 *
 * @param {EventDetailResponse} eventDetailResponse
 * @param {string} eventId
 * @returns {{tabList: [FilterTab], bannerData: BannerData, tab: {[string]: TabObj}, section: {[string]: SectionObj}, odd: {[string]: OddObj}}
 */
export const createEventDetailData = (eventDetailResponse, eventId) => {
	const {
		info = {},
		normal = {},
		specials = [],
		corners,
		bookings,
		alternateLines,
		gameMarkets,
		setMarkets,
		kills,
		version,
		specialVersion,
		matchMarkets,
		match180Markets,
		pointsMarkets,
		hitsRunsErrors,
	} = eventDetailResponse
	const { homeTeam, awayTeam } = getTeam(normal?.participants)
	const isSwapHomeAway = normal?.homeTeamType === 1
	const homeTeamCode = homeTeam?.englishName
		?.toLowerCase()
		?.replaceAll(' ', '-')
	const awayTeamCode = awayTeam?.englishName
		?.toLowerCase()
		?.replaceAll(' ', '-')
	const bannerData = {
		eventId,
		sportCode: SPORT_ID?.[info?.sportId],
		sportId: info?.sportId,
		sportName: appIntl().formatMessage({
			id: `sport_name.${SPORT_ID[info?.sportId]}`,
			defaultMessage: info?.sportName,
		}),
		leagueId: info?.leagueId,
		leagueCode: info?.leagueCode,
		leagueName: info?.leagueName,
		gameCode: info?.gameCode,
		gameName: info?.gameName,
		region: info?.container,
		time: normal?.time,
		eventName: generateEventName(normal?.homeTeamType, normal?.participants),
		homeTeam: homeTeam?.name,
		homeTeamCode: homeTeam?.englishName?.toLowerCase()?.replaceAll(' ', '-'),
		homeTeamEnglishName: homeTeam?.englishName,
		homeTeamScore: createScoreBoard(homeTeam?.score, info?.sportId),
		homeTeamRedCard: sumObject(homeTeam?.redCards),
		homeTeamYellowCard: homeTeam?.yellowCards,
		homeTeamCorner: homeTeam?.cornerScore,
		awayTeam: awayTeam?.name,
		awayTeamCode: awayTeam?.englishName?.toLowerCase()?.replaceAll(' ', '-'),
		awayTeamEnglishName: awayTeam?.englishName,
		awayTeamScore: createScoreBoard(awayTeam?.score, info?.sportId),
		awayTeamRedCard: sumObject(awayTeam?.redCards),
		awayTeamYellowCard: awayTeam?.yellowCards,
		awayTeamCorner: awayTeam?.cornerScore,
		isLive: !!normal?.runningState,
		rotNum: normal?.rotNum,
		elapsed: normal?.elapsed,
		runningState: normal?.runningState,
		resultingUnit: info?.resultingUnit,
		hasLiveStream:
			normal?.hasLiveStream && window?.env?.enableEsportsLiveStreamModule,
		liveStreamUrls: info?.videoFeeds,
		hasScoreboard:
			normal?.hasScoreboard && window?.env?.enableEsportsLiveScoreboardModule,
		scoreboardSeriesId: info?.seriesId,
		isSwapHomeAway,
		eventEnglishNamePath: isSwapHomeAway
			? `${awayTeamCode}-vs-${homeTeamCode}`
			: `${homeTeamCode}-vs-${awayTeamCode}`,
	}

	const tabList = createTabList({
		sportId: info?.sportId,
		resultingUnit: info?.resultingUnit,
		region: info?.container,
		normal,
		corners,
		bookings,
		kills,
		alternateLines,
		gameMarkets,
		setMarkets,
		specials,
		matchMarkets,
		match180Markets,
		pointsMarkets,
		hitsRunsErrors,
	})

	const {
		tab,
		section,
		odd,
		tabList: finalTabList,
	} = createTabData({
		tabList,
		normal,
		corners,
		bookings,
		specials,
		kills,
		alternateLines,
		setMarkets,
		gameMarkets,
		matchMarkets,
		match180Markets,
		pointsMarkets,
		hitsRunsErrors,
		sportId: info?.sportId,
		bannerData,
		resultingUnit: info?.resultingUnit,
	})
	return {
		bannerData,
		tabList: finalTabList,
		tab,
		section,
		odd,
		version,
		specialVersion,
		eventId,
	}
}

export const updateBannerDataLiveSoccer = ({ oddBanner, normal }) => {
	let result = oddBanner
	const { homeTeam, awayTeam } = getTeam(normal?.participants)

	if (normal) {
		result = {
			...result,
			elapsed: normal?.elapsed,
			runningState: normal?.runningState,
			homeTeamScore: createScoreBoard(homeTeam?.score, oddBanner?.sportId),
			homeTeamRedCard: sumObject(homeTeam?.redCards),
			homeTeamYellowCard: homeTeam?.yellowCards,
			awayTeamScore: createScoreBoard(awayTeam?.score, oddBanner?.sportId),
			awayTeamRedCard: sumObject(awayTeam?.redCards),
			awayTeamYellowCard: awayTeam?.yellowCards,
		}
	}

	if (
		typeof homeTeam?.cornerScore === 'number' &&
		typeof awayTeam?.cornerScore === 'number'
	) {
		result = {
			...result,
			homeTeamCorner: homeTeam.cornerScore,
			awayTeamCorner: awayTeam.cornerScore,
		}
	}

	return result
}

const createTabList = ({
	sportId,
	region,
	resultingUnit,
	normal,
	corners,
	bookings,
	kills,
	alternateLines,
	gameMarkets,
	setMarkets,
	pointsMarkets,
	specials,
	hitsRunsErrors,
}) => {
	let tabList = [MARKET_TAB?.ALL]
	const normalPeriodKeys = Object.keys(normal?.periods || {})

	// Handle only for Hockey
	if (
		sportId === SPORT_NAME.hockey &&
		![
			REGION_CONTAINER.NORTH_AMERICAN,
			REGION_CONTAINER.AMERICAN,
			REGION_CONTAINER.CANADA,
			REGION_CONTAINER.USA,
		].some((item) => item === region)
	) {
		const regulationTimePeriodIndex = normalPeriodKeys.indexOf('6')
		if (regulationTimePeriodIndex > -1) {
			normalPeriodKeys.splice(regulationTimePeriodIndex, 1)
			normalPeriodKeys.unshift('6')
		}
	}

	normalPeriodKeys.forEach((key) => {
		tabList.push({
			value: key,
			keyTranslate: '',
			defaultMessage: `${sportId}.${key}`,
			periodKey: `${sportId}.${key}`,
			hash: `#period:${key}`,
			type: MARKET_TAB_TYPE.PERIODS,
			show: true,
		})
	})
	if (corners) tabList.push(MARKET_TAB?.CORNERS)
	if (bookings) tabList.push(MARKET_TAB?.BOOKINGS)
	if (kills) tabList.push(MARKET_TAB?.KILLS)
	if (alternateLines) tabList.push(MARKET_TAB?.ALTERNATE_LINES)

	// Handle only for Tennis
	const isTennisGames =
		sportId === SPORT_NAME.tennis && resultingUnit === RESULTING_UNIT.GAMES
	const isTennisSets =
		sportId === SPORT_NAME.tennis && resultingUnit === RESULTING_UNIT.SETS
	if (isTennisGames || gameMarkets) tabList.push(MARKET_TAB?.GAME_MARKETS)
	if (isTennisSets || setMarkets) tabList.push(MARKET_TAB?.SET_MARKETS)

	// Handle only for Baseball
	if (sportId === SPORT_NAME.baseball && hitsRunsErrors) {
		tabList.push(MARKET_TAB?.HITS_RUNS_ERRORS_MARKETS)
	}

	// Handle only for Dart
	if (sportId === SPORT_NAME.darts) tabList.push(MARKET_TAB?.DART_180s_MARKETS)

	// Handle only for Volleyball
	if (sportId === SPORT_NAME.volleyball && pointsMarkets) {
		const tabPointsMarkets = { ...MARKET_TAB?.POINTS_MARKETS }
		const key = Object.keys(pointsMarkets?.periods || [])?.[0]
		tabPointsMarkets.defaultMessage = `${sportId}.${key}`
		tabPointsMarkets.periodKey = `${sportId}.${key}`
		tabPointsMarkets.hash = `#period:${key}`
		key && tabList.push(tabPointsMarkets)
	}

	let specialsTabs = []

	specials?.forEach((period) => {
		specialsTabs.push({
			value: period?.code,
			keyTranslate: '',
			defaultMessage: period?.name,
			periodKey: '',
			hash: `#${period?.code}`,
			type: MARKET_TAB_TYPE.SPECIALS,
			show: true,
		})
	})

	specialsTabs = specialsTabs.sort((tabA, tabB) =>
		tabA?.defaultMessage?.localeCompare(tabB?.defaultMessage),
	)
	tabList = [...tabList, ...specialsTabs]
	return tabList
}

const handleDataNormal = ({
	sportId,
	resultingUnit,
	normal,
	setMarkets,
	gameMarkets,
	matchMarkets,
	match180Markets,
}) => {
	const result = {
		setMarkets: null,
		gameMarkets: null,
		normal: normal,
		match180Markets: null,
	}
	switch (sportId) {
		case SPORT_NAME.tennis: {
			const isTennisGames = resultingUnit === RESULTING_UNIT.GAMES
			const isTennisSets = resultingUnit === RESULTING_UNIT.SETS
			result.setMarkets =
				resultingUnit === RESULTING_UNIT.SETS ? normal : setMarkets
			result.gameMarkets =
				resultingUnit === RESULTING_UNIT.GAMES ? normal : gameMarkets
			result.normal = isTennisGames || isTennisSets ? {} : normal
			break
		}
		case SPORT_NAME.darts: {
			const isDart180s = resultingUnit === RESULTING_UNIT.DART_180S
			result.normal = isDart180s ? matchMarkets : normal
			result.match180Markets = isDart180s ? normal : match180Markets
			break
		}
		default: {
			break
		}
	}
	return result
}

const createNumberSectionShowDefault = ({
	sportId,
	tab,
	tabList,
	resultingUnit,
}) => {
	switch (sportId) {
		case SPORT_NAME.tennis: {
			let mainTab = ''
			if (resultingUnit === RESULTING_UNIT.SETS) {
				mainTab = MARKET_TAB_TYPE?.SET_MARKETS
			}
			if (resultingUnit === RESULTING_UNIT.GAMES) {
				mainTab = MARKET_TAB_TYPE?.GAME_MARKETS
			}
			if (mainTab) {
				const firstPeriod = tab[mainTab]?.sectionListIds?.[0].split('-')?.[0]
				return tab[mainTab]?.sectionListIds?.reduce((total, sectionId) => {
					const period = sectionId?.split('-')?.[0]
					if (period === firstPeriod) {
						total += 1
					}
					return total
				}, 0)
			}
			return 0
		}
		default: {
			return tabList?.length === 1
				? tab[tabList?.[0]?.value].sectionListIds?.length
				: tab[tabList?.[1]?.value].sectionListIds?.length
		}
	}
}

/**
 *
 * @param tabList
 * @param normal
 * @param corners
 * @param bookings
 * @param sportId
 * @param kills
 * @param alternateLines
 * @param setMarkets
 * @param gameMarkets
 * @param specials
 * @param matchMarkets
 * @param match180Markets
 * @param pointsMarkets
 * @param hitsRunsErrors
 * @param {BannerData} bannerData
 * @param {string=} resultingUnit
 * @returns {{tab: {[string]: TabObj}, section: {[string]: SectionObj}, odd: {[string]: OddObj}}, tabList}
 */
export const createTabData = ({
	sportId,
	tabList,
	bannerData,
	resultingUnit,
	normal,
	corners,
	bookings,
	kills,
	alternateLines,
	setMarkets,
	gameMarkets,
	specials,
	matchMarkets,
	match180Markets,
	pointsMarkets,
	hitsRunsErrors,
}) => {
	let tab = {}
	let section = {}
	let odd = {}
	let oddData = {}
	const {
		normal: finalNormal,
		gameMarkets: finalGameMarkets,
		setMarkets: finalSetMarkets,
		match180Markets: finalMatch180Markets,
	} = handleDataNormal({
		sportId,
		resultingUnit,
		normal,
		setMarkets,
		gameMarkets,
		matchMarkets,
		match180Markets,
	})
	tabList.forEach((tabItem) => {
		tab[tabItem.value] = {
			value: tabItem?.value,
			name: tabItem?.defaultMessage,
			type: tabItem?.type,
			sectionListIds: [],
		}
		oddData = {}
		switch (tabItem.type) {
			case MARKET_TAB_TYPE.PERIODS: {
				if (isEmpty(finalNormal)) {
					break
				}
				oddData = createNormalOddData({
					sportId,
					normalData: finalNormal,
					tabData: tabItem,
					bannerData,
					type: MARKET_TAB_TYPE.PERIODS,
				})
				break
			}
			case MARKET_TAB_TYPE.CORNERS: {
				if (isEmpty(corners)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: corners,
					tabData: tabItem,
					bannerData,
					subName: 'Corners',
					subKeyTranslate: 'market.tab.corners',
					type: MARKET_TAB_TYPE.CORNERS,
				})
				break
			}
			case MARKET_TAB_TYPE.BOOKINGS: {
				if (isEmpty(bookings)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: bookings,
					tabData: tabItem,
					bannerData,
					subName: 'Bookings',
					subKeyTranslate: 'market.tab.bookings',
					type: MARKET_TAB_TYPE.BOOKINGS,
				})
				break
			}
			case MARKET_TAB_TYPE.SET_MARKETS: {
				if (isEmpty(finalSetMarkets)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: finalSetMarkets,
					tabData: tabItem,
					bannerData: {
						...bannerData,
						resultingUnit: finalSetMarkets.resultingUnit,
						eventName: generateEventName(
							finalSetMarkets?.homeTeamType,
							finalSetMarkets?.participants,
						),
					},
					subName: RESULTING_UNIT.SETS,
					subKeyTranslate: 'event_detail.sets',
					type: MARKET_TAB_TYPE.SET_MARKETS,
				})
				break
			}
			case MARKET_TAB_TYPE.GAME_MARKETS: {
				if (isEmpty(finalGameMarkets)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: finalGameMarkets,
					tabData: tabItem,
					bannerData: {
						...bannerData,
						resultingUnit: finalGameMarkets.resultingUnit,
						eventName: generateEventName(
							finalGameMarkets?.homeTeamType,
							finalGameMarkets?.participants,
						),
					},
					subName: RESULTING_UNIT.GAMES,
					subKeyTranslate: 'event_detail.games',
					type: MARKET_TAB_TYPE.GAME_MARKETS,
				})
				break
			}
			case MARKET_TAB_TYPE.DART_180S_MARKETS: {
				if (isEmpty(finalMatch180Markets)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: finalMatch180Markets,
					tabData: tabItem,
					bannerData,
					subName: RESULTING_UNIT.DART_180S,
					subKeyTranslate: 'event_detail.180s',
					type: MARKET_TAB_TYPE.DART_180S_MARKETS,
				})
				break
			}
			case MARKET_TAB_TYPE.KILLS: {
				if (isEmpty(kills)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: kills,
					tabData: tabItem,
					bannerData,
					subName: 'Kills',
					subKeyTranslate: 'market.tab.kills',
					type: MARKET_TAB_TYPE.KILLS,
				})
				break
			}
			case MARKET_TAB_TYPE.ALTERNATE_LINES: {
				if (isEmpty(alternateLines)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: alternateLines,
					tabData: tabItem,
					bannerData,
					subName: 'Alternate Lines',
					subKeyTranslate: 'market.tab.alternate_lines',
					type: MARKET_TAB_TYPE.ALTERNATE_LINES,
				})
				break
			}
			case MARKET_TAB_TYPE.POINTS_MARKETS: {
				if (isEmpty(pointsMarkets)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: pointsMarkets,
					tabData: tabItem,
					bannerData,
					subName: RESULTING_UNIT.POINTS,
					subKeyTranslate: 'event_detail.points_markets',
					type: MARKET_TAB_TYPE.POINTS_MARKETS,
				})
				break
			}
			case MARKET_TAB_TYPE.HITS_RUNS_ERRORS_MARKETS: {
				if (isEmpty(hitsRunsErrors)) {
					break
				}
				oddData = createSubOddData({
					sportId,
					responseData: hitsRunsErrors,
					tabData: tabItem,
					bannerData,
					subName: RESULTING_UNIT.HITS_RUNS_ERRORS,
					subKeyTranslate: 'market.tab.hits_runs_errors',
					type: MARKET_TAB_TYPE.HITS_RUNS_ERRORS_MARKETS,
				})
				break
			}
			case MARKET_TAB_TYPE.SPECIALS: {
				if (isEmpty(specials) || isEmpty(normal)) {
					break
				}
				const specialsData = specials?.find(
					(data) => data.code === tabItem?.value,
				)
				oddData = createSpecialOddData({
					specialsData,
					tabData: tabItem,
					bannerData,
					participants: normal?.participants,
				})
				break
			}
			default: {
				break
			}
		}
		if (oddData?.section) {
			Object.keys(oddData?.section).forEach((sectionId) => {
				oddData.section[sectionId].isPinned = false
			})
			section = { ...section, ...oddData?.section }
		}
		odd = { ...odd, ...oddData?.odd }
		tab[tabItem.value].sectionListIds = oddData?.sectionListIds || []
	})
	const { newTab, newTabList } = organizeTabData({
		tabList,
		section,
		sportId,
		tab,
		resultingUnit,
	})

	return { odd, section, tab: newTab, tabList: newTabList }
}

const organizeTabData = ({ tabList, section, sportId, tab, resultingUnit }) => {
	const tabKey = tabList.map((item) => item.value)
	const newTab = cloneDeep(tab)
	const periodGroups = groupBy(section, 'periodValue')
	tabKey?.forEach((key) => {
		const tabData = newTab?.[key]
		const tabAllData = newTab[MARKET_TAB.ALL.value]
		const sectionListIds = tabData?.sectionListIds || []
		switch (sportId) {
			case SPORT_NAME.tennis: {
				if (tabData?.type !== MARKET_TAB_TYPE.ALL) {
					if (
						(tabData?.type === MARKET_TAB_TYPE.GAME_MARKETS &&
							resultingUnit === RESULTING_UNIT.GAMES) ||
						(tabData?.type === MARKET_TAB_TYPE.SET_MARKETS &&
							resultingUnit === RESULTING_UNIT.SETS)
					) {
						tabAllData.sectionListIds = [
							...sectionListIds,
							...tabAllData.sectionListIds,
						]
					} else {
						tabAllData.sectionListIds = [
							...tabAllData.sectionListIds,
							...sectionListIds,
						]
					}
				}
				if (
					tabData?.type === MARKET_TAB_TYPE.PERIODS &&
					tabData?.value !== PERIOD.MATCH
				) {
					newTab[key].sectionListIds = periodGroups[key]
						?.map((item) => item.id)
						?.sort(
							(sectionIdA, sectionIdB) =>
								section[sectionIdA]?.order - section[sectionIdB]?.order,
						)
				}
				if (
					tabData?.type === MARKET_TAB_TYPE.PERIODS &&
					tabData?.value === PERIOD.MATCH
				) {
					const sectionIds = periodGroups[key]
						?.map((item) => item.id)
						?.sort(
							(sectionIdA, sectionIdB) =>
								section[sectionIdA]?.order - section[sectionIdB]?.order,
						)
					const setSectionIds =
						sectionIds?.filter((sectionId) =>
							sectionId.includes(MARKET_TAB_TYPE.SET_MARKETS),
						) || []
					const gameSectionIds =
						sectionIds?.filter((sectionId) =>
							sectionId.includes(MARKET_TAB_TYPE.GAME_MARKETS),
						) || []
					newTab[key].sectionListIds = [...setSectionIds, ...gameSectionIds]
				}
				break
			}
			case SPORT_NAME.darts: {
				if (tabData?.value !== PERIOD.MATCH) {
					newTab[PERIOD.MATCH].sectionListIds = [
						...newTab[PERIOD.MATCH].sectionListIds,
						...sectionListIds,
					]
				}
				break
			}
			default: {
				if (tabData?.type !== MARKET_TAB_TYPE.ALL) {
					tabAllData.sectionListIds = [
						...tabAllData.sectionListIds,
						...sectionListIds,
					]
				}
				break
			}
		}
	})
	let newTabList = tabList?.filter((newTab) => newTab.show)
	newTabList = newTabList.map((newTab, index) => ({ ...newTab, index }))
	const secondTab = newTabList?.[1]
	if (newTabList?.length > 2 && secondTab?.value) {
		newTab[secondTab.value].hasSeeAllMarketsButton = true
	}
	if (newTabList?.length === 2 && secondTab?.value) {
		newTabList = [{ ...secondTab, index: 0 }]
	}
	newTab[newTabList[0]?.value].numberSectionShowDefault =
		createNumberSectionShowDefault({
			sportId,
			tab: newTab,
			tabList: newTabList,
			resultingUnit,
		})

	// Remove duplicate section in sectionListIds
	const newTabKeys = Object.keys(newTab)
	newTabKeys.forEach((key) => {
		newTab[key].sectionListIds = [...new Set(newTab[key].sectionListIds)]
	})

	return { newTab, newTabList }
}

/**
 *
 * @param {number} sportId
 * @param normalData
 * @param {TabObj} tabData
 * @param {BannerData} bannerData
 * @param {string} type
 * @returns {{sectionListIds: [string: sectionId], section: {[string]: SectionObj}, odd: {[string]: OddObj}}}
 */
const createNormalOddData = ({
	sportId,
	normalData,
	tabData,
	bannerData,
	type,
}) => {
	const section = {}
	let sectionListIds = []
	let odd = {}
	const eventId = normalData?.id
	const periodValue = tabData?.value
	const periodKey = `${sportId}.${tabData?.value}`
	const periods = normalData?.periods?.[periodValue]
	const betTypeKeys = Object.keys(periods)
	betTypeKeys?.forEach((betTypeKey) => {
		const config = getConfigBySportId(sportId)
		if (config[betTypeKey]?.key) {
			const {
				order,
				hasHomeAwayHeader,
				hasMainLine,
				defaultMessage,
				keyTranslate: periodKeyTranslation,
			} = config[betTypeKey] || {}
			const sectionId = `${periodValue}-${betTypeKey}`
			const oddData = createPeriodOddData({
				config,
				dataOdd: periods[betTypeKey],
				betType: betTypeKey,
				periodValue,
				eventId,
				eventData: normalData,
				bannerData,
				parlayRestriction: normalData?.parlayRestriction,
				rotNum: normalData?.rotNum,
			})
			if (oddData && oddData?.oddIds?.length) {
				odd = { ...odd, ...oddData?.odd }
				section[sectionId] = {
					id: sectionId,
					periodValue,
					homeTeamName: normalData?.participants?.[0]?.name,
					awayTeamName: normalData?.participants?.[1]?.name,
					oddIds: oddData?.oddIds,
					columns: oddData?.columns,
					order,
					hasHomeAwayHeader,
					defaultMessage: `${defaultMessage}`,
					keyTranslates: { periodKeyTranslation, periodKey },
					hasMainLine,
					mainLineIds: hasMainLine
						? oddData?.oddIds.filter((oddId) => !odd[oddId]?.isAlt)
						: [],
					participants: normalData?.participants,
					isSwapHomeAway: [
						config?.moneyLine?.key,
						config?.handicap?.key,
						config?.teamTotals?.key,
					].some((key) => key === betTypeKey)
						? bannerData?.isSwapHomeAway
						: false,
					type,
				}
				sectionListIds.push(sectionId)
			}
		}
	})
	sectionListIds = sectionListIds.sort(
		(sectionIdA, sectionIdB) =>
			section[sectionIdA]?.order - section[sectionIdB]?.order,
	)
	return { section, sectionListIds, odd }
}

/**
 *
 * @param {BannerData} bannerData
 * @param {string} type
 * @returns {{[string]: {keyTranslate: string, firstPoint: number, secondPoint: number}}
 */
const createSubTitle = ({ bannerData, type }) => {
	switch (type) {
		case MARKET_TAB_TYPE.CORNERS: {
			if (bannerData?.isLive) {
				return {
					[MARKET_TAB_TYPE.CORNERS]: {
						keyTranslate: 'event_detail.current_corners',
						...(bannerData?.isSwapHomeAway
							? {
									firstPoint: bannerData?.awayTeamCorner,
									secondPoint: bannerData?.homeTeamCorner,
							  }
							: {
									firstPoint: bannerData?.homeTeamCorner,
									secondPoint: bannerData?.awayTeamCorner,
							  }),
					},
				}
			}
			return null
		}
		default: {
			return null
		}
	}
}

/**
 *
 * @param {number} sportId
 * @param responseData
 * @param {TabObj} tabData
 * @param {string} subName
 * @param {string} subKeyTranslate
 * @param {BannerData} bannerData
 * @param {string} type
 * @returns {{sectionListIds: [string: sectionId], section: {[string]: SectionObj}, odd: {[string]: OddObj}}}
 */
export const createSubOddData = ({
	sportId,
	responseData,
	tabData,
	subName,
	subKeyTranslate,
	bannerData,
	type,
}) => {
	const section = {}
	let sectionListIds = []
	let odd = {}
	const allPeriods = responseData?.periods
	const periodsValues = Object.keys(allPeriods)
	const eventId = responseData?.id
	periodsValues.forEach((periodValue) => {
		const periods = responseData?.periods[periodValue]
		const betTypeKeys = Object.keys(periods)
		const periodKey = `${sportId}.${periodValue}`
		betTypeKeys?.forEach((betTypeKey) => {
			const config = getConfigBySportId(sportId)
			if (config[betTypeKey]?.key) {
				const {
					order,
					hasHomeAwayHeader,
					defaultMessage,
					keyTranslate: periodKeyTranslation,
				} = config[betTypeKey] || {}
				const sectionId = `${periodValue}-${betTypeKey}-${tabData?.value}`
				const oddData = createPeriodOddData({
					config,
					dataOdd: periods[betTypeKey],
					betType: betTypeKey,
					periodValue,
					eventId,
					eventData: responseData,
					bannerData,
					subName,
					subKeyTranslate,
					parlayRestriction: responseData?.parlayRestriction,
					rotNum: responseData?.rotNum,
				})
				if (oddData && oddData?.oddIds?.length) {
					odd = { ...odd, ...oddData?.odd }
					section[sectionId] = {
						id: sectionId,
						periodValue,
						homeTeamName: responseData?.participants?.[0]?.name,
						awayTeamName: responseData?.participants?.[1]?.name,
						oddIds: oddData?.oddIds,
						columns: oddData?.columns,
						order,
						hasHomeAwayHeader,
						defaultMessage: `${defaultMessage} (${subName})`,
						keyTranslates: {
							periodKeyTranslation,
							subKeyTranslate,
							periodKey,
						},
						hasMainLine: false,
						mainLineIds: [],
						participants: responseData?.participants,
						isSwapHomeAway: [
							config?.moneyLine?.key,
							config?.handicap?.key,
							config?.teamTotals?.key,
						].some((key) => key === betTypeKey)
							? bannerData?.isSwapHomeAway
							: false,
						subTitleData: createSubTitle({ bannerData, type }),
						type,
					}
					sectionListIds.push(sectionId)
				}
			}
		})
	})

	sectionListIds = sectionListIds.sort(
		(sectionIdA, sectionIdB) =>
			section[sectionIdA]?.order - section[sectionIdB]?.order,
	)

	if (sportId === SPORT_NAME.tennis) {
		sectionListIds = sectionListIds.sort((sectionIdA, sectionIdB) => {
			const sectionPeriodA = parseInt(section[sectionIdA]?.periodValue)
			const sectionPeriodB = parseInt(section[sectionIdB]?.periodValue)
			return sectionPeriodA - sectionPeriodB
		})
		if (subName === RESULTING_UNIT.SETS) {
			const firstSection = section?.[sectionListIds?.[0]]
			if (firstSection.id === '0-moneyLine-set-markets') {
				section[sectionListIds[0]].keyTranslates = {
					periodKeyTranslation: 'event_detail.match_winner',
				}
			}
		}
	}

	return { section, sectionListIds, odd }
}

/**
 *
 * @param specialsData
 * @param {TabObj} tabData
 * @param {BannerData} bannerData
 * @param participants
 * @returns {{sectionListIds: [string: sectionId], section: {[string]: SectionObj}, odd: {[string]: OddObj}}}
 */
const createSpecialOddData = ({
	specialsData,
	tabData,
	bannerData,
	participants,
}) => {
	const section = {}
	let sectionListIds = []
	let odd = {}
	const { events } = specialsData
	events?.forEach((event) => {
		const { id, contestants, name, bt, un } = event
		const oddIds = []
		contestants?.forEach((item) => {
			const { i, n, p, l, h } = item
			const oddId = `${id}|0|99|10|0|${h || 0}|${i}`
			odd[oddId] = {
				id: oddId,
				defaultMessage: bt === 'OVER_UNDER' ? `${n} ${h} ${un}` : n,
				keyTranslates: null,
				value: p,
				type: 'OUTRIGHT',
				lineId: l,
				isAlt: false,
				participants,
				teamType: 0,
				awayTeamType: '',
				homeTeamType: '',
				contestantName: name,
				bannerData,
				status: 'NONE',
				isSpecial: true,
			}
			oddIds.push(oddId)
		})
		const sectionId = `${tabData?.value}-${id}`
		section[sectionId] = {
			id: sectionId,
			periodValue: '',
			homeTeamName: '',
			awayTeamName: '',
			oddIds: oddIds,
			columns: oddIds?.length === 2 ? 2 : 1,
			order: 1,
			hasHomeAwayHeader: false,
			defaultMessage: name,
			keyTranslates: null,
			hasMainLine: false,
			mainLineIds: [],
			participants: [],
			isSwapHomeAway: false,
			parlayRestriction: null,
			rotNum: null,
		}
		sectionListIds.push(sectionId)
	})
	sectionListIds = sectionListIds?.sort((sectionIdA, sectionIdB) =>
		section[sectionIdA].defaultMessage?.localeCompare(
			section[sectionIdB].defaultMessage,
		),
	)
	return { section, sectionListIds, odd }
}

/**
 *
 * @param config
 * @param dataOdd
 * @param {string} betType
 * @param {string|number} periodValue
 * @param {number} eventId
 * @param eventData
 * @param {BannerData} bannerData
 * @param {string=} subName
 * @param {string=} subKeyTranslate
 * @param parlayRestriction
 * @param rotNum
 * @returns {{odd: {[string]: OddObj}, oddIds: [string], columns: number}}
 */
const createPeriodOddData = ({
	config,
	dataOdd,
	betType,
	periodValue,
	eventId,
	eventData = {},
	bannerData,
	subName,
	subKeyTranslate,
	parlayRestriction,
	rotNum,
}) => {
	if (dataOdd?.unavailable || dataOdd?.[0]?.unavailable) {
		return {
			odd: {},
			oddIds: [],
			columns: 0,
		}
	}
	const arg = {
		config,
		dataOdd,
		eventId,
		periodValue,
		eventData,
		bannerData,
		subName,
		subKeyTranslate,
		parlayRestriction,
		rotNum,
	}
	switch (betType) {
		case config?.moneyLine?.key: {
			const { odd, oddIds, columns } = createMoneyLineOdd(arg)
			return { odd, oddIds, columns }
		}
		case config?.handicap?.key: {
			const { odd, oddIds, columns } = createHandicapOdd(arg)
			return { odd, oddIds, columns }
		}
		case config?.overUnder?.key: {
			const { odd, oddIds, columns } = createOverUnderOdd(arg)
			return { odd, oddIds, columns }
		}
		case config?.teamTotals?.key: {
			const { odd, oddIds, columns } = createTeamTotalOdd(arg)
			return { odd, oddIds, columns }
		}
		default: {
			return { odd: {}, oddIds: [], columns: 0 }
		}
	}
}

/**
 *
 * @param config
 * @param dataOdd
 * @param {number} eventId
 * @param {string|number} periodValue
 * @param eventData
 * @param {BannerData} bannerData
 * @param parlayRestriction
 * @param rotNum
 * @returns {{odd: {[string]: OddObj}, oddIds: [string], columns: number}}
 */
const createHandicapOdd = ({
	config,
	dataOdd,
	eventId,
	periodValue,
	eventData,
	parlayRestriction,
	rotNum,
}) => {
	const odd = {}
	const oddIds = []
	const { betTypeValue, columns } = config?.handicap || {}
	const { homeTeamType, awayTeamType, participants } = eventData || {}
	dataOdd.forEach(
		({
			isAlt,
			homeSpread,
			awaySpread,
			homeOdds,
			awayOdds,
			lineId,
			offline,
		}) => {
			const altValue = isAlt ? '1' : '0'
			const oddHomeId = `${eventId}|${periodValue}|${betTypeValue}|0|${altValue}|${homeSpread}`
			const oddAwayId = `${eventId}|${periodValue}|${betTypeValue}|1|${altValue}|${awaySpread}`
			odd[oddHomeId] = {
				eventId,
				id: oddHomeId,
				defaultMessage: homeSpread,
				keyTranslates: null,
				value: homeOdds,
				type: 'NORMAL',
				lineId: lineId,
				isAlt,
				offline,
				teamType: homeTeamType,
				awayTeamType,
				homeTeamType,
				participants,
				status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
				parlayRestriction,
				rotNum,
			}
			odd[oddAwayId] = {
				eventId,
				id: oddAwayId,
				defaultMessage: awaySpread,
				keyTranslates: null,
				value: awayOdds,
				type: 'NORMAL',
				lineId: lineId,
				isAlt,
				offline,
				teamType: awayTeamType,
				awayTeamType,
				homeTeamType,
				participants,
				status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
				parlayRestriction,
				rotNum,
			}
			oddIds.push(oddHomeId)
			oddIds.push(oddAwayId)
		},
	)
	return { odd, oddIds, columns }
}

/**
 *
 * @param config
 * @param dataOdd
 * @param {number} eventId
 * @param {string|number} periodValue
 * @param eventData
 * @param {BannerData} bannerData
 * @param parlayRestriction
 * @param rotNum
 * @param {string=} subKeyTranslate
 * @returns {{odd: {[string]: OddObj}, oddIds: [string], columns: number}}
 */
const createMoneyLineOdd = ({
	config,
	dataOdd = {},
	eventId,
	periodValue,
	eventData,
	bannerData,
	parlayRestriction,
	rotNum,
}) => {
	const odd = {}
	const { betTypeValue, columns } = config?.moneyLine || {}
	const { homePrice, awayPrice, drawPrice, lineId, offline } = dataOdd || {}
	const oddHomeId = `${eventId}|${periodValue}|${betTypeValue}|0|0|0`
	const oddAwayId = `${eventId}|${periodValue}|${betTypeValue}|1|0|0`
	const { homeTeamType, awayTeamType, participants } = eventData || {}
	let oddDrawId = ''
	odd[oddHomeId] = {
		eventId,
		id: oddHomeId,
		defaultMessage: participants?.[0]?.name,
		keyTranslates: null,
		value: homePrice,
		type: 'NORMAL',
		lineId,
		isAlt: false,
		offline,
		teamType: homeTeamType,
		awayTeamType,
		homeTeamType,
		participants,
		status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
	}
	odd[oddAwayId] = {
		id: oddAwayId,
		defaultMessage: participants?.[1]?.name,
		keyTranslates: null,
		value: awayPrice,
		type: 'NORMAL',
		lineId,
		isAlt: false,
		offline,
		teamType: awayTeamType,
		awayTeamType,
		homeTeamType,
		participants,
		bannerData,
		status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
	}
	if (typeof drawPrice === 'string') {
		oddDrawId = `${eventId}|${periodValue}|${betTypeValue}|2|0|0`
		odd[oddDrawId] = {
			eventId,
			id: oddDrawId,
			defaultMessage: 'Draw',
			keyTranslates: { draw: 'event_detail.draw' },
			value: drawPrice,
			type: 'NORMAL',
			lineId,
			isAlt: false,
			offline,
			teamType: 2,
			participants,
			awayTeamType,
			homeTeamType,
			status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
			parlayRestriction,
			rotNum,
		}
	}

	const oddIds = oddDrawId
		? [oddHomeId, oddDrawId, oddAwayId]
		: [oddHomeId, oddAwayId]

	if (checkIsMultiviewPage()) {
		return {
			odd,
			oddIds: bannerData?.isSwapHomeAway ? oddIds.reverse() : oddIds,
			columns: 1,
		}
	}

	return {
		odd,
		oddIds,
		columns: oddDrawId ? columns : columns - 1,
	}
}

/**
 *
 * @param config
 * @param dataOdd
 * @param {number} eventId
 * @param {string|number} periodValue
 * @param eventData
 * @param {BannerData} bannerData
 * @param {string} subName
 * @param {string=} subKeyTranslate
 * @param parlayRestriction
 * @param rotNum
 * @returns {{odd: {[string]: OddObj}, oddIds: [string], columns: number}}
 */
const createOverUnderOdd = ({
	config,
	dataOdd,
	eventId,
	periodValue,
	eventData,
	bannerData,
	subName = '',
	subKeyTranslate = '',
	parlayRestriction,
	rotNum,
}) => {
	const odd = {}
	const oddIds = []
	const { betTypeValue, columns } = config?.overUnder || {}
	const { homeTeamType, awayTeamType, participants } = eventData || {}
	dataOdd.forEach(({ isAlt, points, overOdds, underOdds, lineId, offline }) => {
		const altValue = isAlt ? '1' : '0'
		const oddOverId = `${eventId}|${periodValue}|${betTypeValue}|3|${altValue}|${points}`
		const oddUnderId = `${eventId}|${periodValue}|${betTypeValue}|4|${altValue}|${points}`
		odd[oddOverId] = {
			eventId,
			id: oddOverId,
			defaultMessage: `Over ${points}${subName ? ` ${subName}` : ''}`,
			keyTranslates: { over: 'event_detail.over', subKeyTranslate, points },
			value: overOdds,
			type: 'NORMAL',
			lineId: lineId,
			teamType: homeTeamType,
			isAlt,
			offline,
			participants,
			awayTeamType,
			homeTeamType,
			status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
			parlayRestriction,
			rotNum,
		}
		odd[oddUnderId] = {
			eventId,
			id: oddUnderId,
			defaultMessage: `Under ${points}${subName ? ` ${subName}` : ''}`,
			keyTranslates: { under: 'event_detail.under', subKeyTranslate, points },
			value: underOdds,
			type: 'NORMAL',
			lineId: lineId,
			teamType: awayTeamType,
			isAlt,
			offline,
			participants,
			awayTeamType,
			homeTeamType,
			status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
			parlayRestriction,
			rotNum,
		}
		oddIds.push(oddOverId)
		oddIds.push(oddUnderId)
	})
	return { odd, oddIds, columns }
}

/**
 *
 * @param config
 * @param dataOdd
 * @param {number} eventId
 * @param {string|number} periodValue
 * @param eventData
 * @param {BannerData} bannerData
 * @param {string} subName
 * @param {string} subKeyTranslate
 * @param parlayRestriction
 * @param rotNum
 * @returns {{odd: {[string]: OddObj}, oddIds: [string], columns: number}}
 */
const createTeamTotalOdd = ({
	config,
	dataOdd,
	eventId,
	periodValue,
	eventData,
	bannerData,
	subName = '',
	subKeyTranslate = '',
	parlayRestriction,
	rotNum,
}) => {
	const odd = {}
	let oddIds = []
	const { home, away } = dataOdd || {}
	const {
		overOdds: homeOverOdds,
		underOdds: homeUnderOdds,
		lineId: homeLineId,
		offline: homeOffline,
	} = home
	const {
		overOdds: awayOverOdds,
		underOdds: awayUnderOdds,
		lineId: awayLineId,
		offline: awayOffline,
	} = away
	const { betTypeHomeValue, betTypeAwayValue, columns } =
		config?.teamTotals || {}
	const altHomeValue = home.isAlt ? '1' : '0'
	const altAwayValue = away.isAlt ? '1' : '0'
	const oddHomeOverId = `${eventId}|${periodValue}|${betTypeHomeValue}|5|${altHomeValue}|${home.points}`
	const oddHomeUnderId = `${eventId}|${periodValue}|${betTypeHomeValue}|6|${altHomeValue}|${home.points}`
	const oddAwayOverId = `${eventId}|${periodValue}|${betTypeAwayValue}|7|${altAwayValue}|${away.points}`
	const oddAwayUnderId = `${eventId}|${periodValue}|${betTypeAwayValue}|8|${altAwayValue}|${away.points}`
	const { homeTeamType, awayTeamType, participants } = eventData || {}
	odd[oddHomeOverId] = {
		eventId,
		id: oddHomeOverId,
		defaultMessage: `Over ${home.points}${subName ? ` ${subName}` : ''}`,
		keyTranslates: {
			over: 'event_detail.over',
			subKeyTranslate,
			points: home.points,
		},
		value: homeOverOdds,
		type: 'NORMAL',
		lineId: homeLineId,
		isAlt: false,
		offline: homeOffline,
		teamType: homeTeamType,
		awayTeamType,
		homeTeamType,
		participants,
		status: homeOffline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
	}
	odd[oddHomeUnderId] = {
		eventId,
		id: oddHomeUnderId,
		defaultMessage: `Under ${home.points}${subName ? ` ${subName}` : ''}`,
		keyTranslates: {
			under: 'event_detail.under',
			subKeyTranslate,
			points: home.points,
		},
		value: homeUnderOdds,
		type: 'NORMAL',
		lineId: homeLineId,
		isAlt: false,
		offline: homeOffline,
		teamType: homeTeamType,
		awayTeamType,
		homeTeamType,
		participants,
		status: homeOffline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
	}
	odd[oddAwayOverId] = {
		eventId,
		id: oddAwayOverId,
		defaultMessage: `Over ${away.points}${subName ? ` ${subName}` : ''}`,
		keyTranslates: {
			over: 'event_detail.over',
			subKeyTranslate,
			points: away.points,
		},
		value: awayOverOdds,
		type: 'NORMAL',
		lineId: awayLineId,
		isAlt: false,
		offline: awayOffline,
		teamType: awayTeamType,
		awayTeamType,
		homeTeamType,
		participants,
		status: awayOffline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
	}
	odd[oddAwayUnderId] = {
		eventId,
		id: oddAwayUnderId,
		defaultMessage: `Under ${away.points}${subName ? ` ${subName}` : ''}`,
		keyTranslates: {
			under: 'event_detail.under',
			subKeyTranslate,
			points: away.points,
		},
		value: awayUnderOdds,
		type: 'NORMAL',
		lineId: awayLineId,
		isAlt: false,
		offline: awayOffline,
		teamType: awayTeamType,
		awayTeamType,
		homeTeamType,
		participants,
		status: awayOffline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
	}
	oddIds = [oddHomeOverId, oddAwayOverId, oddHomeUnderId, oddAwayUnderId]
	return { odd, oddIds, columns }
}

export const createUpdatedPeriodOdd = ({
	updatedResponse = [],
	sportId,
	odd,
}) => {
	let oddUpdated = {}
	updatedResponse?.forEach(({ id, periods }) => {
		if (periods) {
			const periodsValues = Object.keys(periods)
			periodsValues.forEach((periodValue) => {
				const betTypeKeys = Object.keys(periods[periodValue])
				betTypeKeys?.forEach((betTypeKey) => {
					const config = getConfigBySportId(sportId)
					if (config[betTypeKey]?.key) {
						const oddData = createPeriodOddData({
							config,
							dataOdd: periods[periodValue]?.[betTypeKey],
							betType: betTypeKey,
							periodValue,
							eventId: id,
							eventData: {},
						})
						oddUpdated = { ...oddUpdated, ...oddData?.odd }
					}
				})
			})
		}
	})
	return updateOddEventDetail({ odd, oddUpdated })
}

export const createUpdatedSpecialOdd = ({ updatedResponse = [], odd = {} }) => {
	let oddUpdated = {}
	updatedResponse.forEach(({ events }) => {
		events?.forEach((event) => {
			const { id, contestants = [], name } = event
			contestants?.forEach((item) => {
				const { i, n, p, l, h } = item
				const oddId = `${id}|0|99|10|0|${h || 0}|${i}`
				oddUpdated[oddId] = {
					id: oddId,
					defaultMessage: n,
					keyTranslates: null,
					value: p,
					type: 'OUTRIGHT',
					lineId: l,
					isAlt: false,
					participants: {},
					teamType: 0,
					awayTeamType: '',
					homeTeamType: '',
					contestantName: name,
					status: 'NONE',
					parlayRestriction: null,
					rotNum: null,
					isSpecial: true,
				}
			})
		})
	})
	return updateOddEventDetail({ odd, oddUpdated })
}

export const updateTabsEventDetail = ({
	availableUpdateData,
	bannerData,
	odd,
	tab,
	section,
	tabList = [],
}) => {
	let newTabList = [...tabList]
	if (!isNil(availableUpdateData?.normal)) {
		const newPeriodTabList = createTabList({
			sportId: bannerData?.sportId,
			resultingUnit: bannerData?.resultingUnit,
			normal: availableUpdateData?.normal,
			region: bannerData?.region,
		})?.filter(
			({ type }) =>
				type === MARKET_TAB_TYPE.ALL || type === MARKET_TAB_TYPE.PERIODS,
		)
		newTabList = newTabList?.filter(
			({ type }) =>
				type !== MARKET_TAB_TYPE.ALL && type !== MARKET_TAB_TYPE.PERIODS,
		)
		newTabList = [...newPeriodTabList, ...newTabList]
	}

	const {
		tab: newTab,
		section: newSection,
		odd: newOld,
	} = createTabData({
		sportId: bannerData?.sportId,
		tabList: newTabList,
		bannerData,
		resultingUnit: bannerData?.resultingUnit,
		...availableUpdateData,
	})
	const oddUpdated = updateOddEventDetail({ odd, oddUpdated: newOld })

	const sectionUpdated = updateSectionEventDetail({
		section,
		sectionUpdated: newSection,
	})

	const { newTab: tabUpdated, newTabList: tadListUpdated } =
		updateTabEventDetail({
			tab,
			tabUpdated: newTab,
			tabList: newTabList,
			bannerData,
			section: sectionUpdated,
		})

	return {
		odd: oddUpdated?.odd,
		section: sectionUpdated,
		tab: tabUpdated,
		tabList: tadListUpdated,
		lastOddUpdatedIds: oddUpdated?.lastOddUpdatedIds,
	}
}

export const updateAllEventDetail = ({
	availableUpdateData,
	bannerData,
	odd,
}) => {
	const tabList = createTabList({
		sportId: bannerData?.sportId,
		resultingUnit: bannerData?.resultingUnit,
		region: bannerData?.region,
		...availableUpdateData,
	})
	const {
		tab,
		section,
		odd: newOld,
	} = createTabData({
		sportId: bannerData?.sportId,
		tabList,
		bannerData,
		resultingUnit: bannerData?.resultingUnit,
		...availableUpdateData,
	})
	const oddUpdated = updateOddEventDetail({
		odd,
		oddUpdated: newOld,
		isUpdateAll: true,
	})
	return {
		tabList,
		tab,
		section,
		odd: oddUpdated?.odd,
		lastOddUpdatedIds: oddUpdated?.lastOddUpdatedIds,
	}
}

export const updateOddEventDetail = ({
	odd,
	oddUpdated,
	isUpdateAll = false,
}) => {
	const oddUpdatedIds = Object.keys(oddUpdated) || []
	const result = isUpdateAll ? { ...oddUpdated } : { ...odd }
	const lastOddUpdatedIds = []
	oddUpdatedIds?.forEach((oddId) => {
		if (odd?.[oddId]) {
			const newOdd = updateObjWithKeys(odd[oddId], oddUpdated[oddId], [
				{ key: 'value', type: 'string', allowFalsy: false },
				{ key: 'offline', type: 'boolean' },
				{ key: 'lineId', type: 'number', allowFalsy: false },
				{ key: 'isAlt', type: 'boolean' },
			])
			const oldValue = parseFloat(odd[oddId].value)
			const newValue = parseFloat(newOdd?.value)
			if (newValue === oldValue) {
				newOdd.status = newOdd?.offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE
			}
			if (newValue > oldValue) {
				newOdd.status = ODD_STATUS.UP
				lastOddUpdatedIds.push(oddId)
			}
			if (newValue < oldValue) {
				newOdd.status = ODD_STATUS.DOWN
				lastOddUpdatedIds.push(oddId)
			}
			result[oddId] = newOdd
		} else {
			result[oddId] = oddUpdated[oddId]
		}
	})
	return { odd: result, lastOddUpdatedIds }
}

const updateTabEventDetail = ({
	tab = {},
	tabUpdated = {},
	tabList = [],
	bannerData,
	section = {},
}) => {
	const temp = {}
	tabList.forEach(({ value }) => {
		if (!tab[value] && !tabUpdated[value]) {
			return
		}
		if (tab[value] && !tabUpdated[value]) {
			temp[value] = tab[value]
		}
		if (!tab[value] && tabUpdated[value]) {
			temp[value] = tabUpdated[value]
		}
		if (tab[value] && tabUpdated[value]) {
			temp[value] = updateObjWithKeys(tab[value], tabUpdated[value], [
				{ key: 'sectionListIds', type: 'array', allowEmpty: false },
			])
		}
	})
	temp[MARKET_TAB_TYPE.ALL].sectionListIds = []
	const { newTab, newTabList } = organizeTabData({
		sportId: bannerData?.sportId,
		tab: temp,
		tabList,
		resultingUnit: bannerData?.resultingUnit,
		section,
	})

	return { newTab, newTabList }
}

const updateSectionEventDetail = ({ section = {}, sectionUpdated = {} }) => {
	const tabUpdatedIds = Object.keys(sectionUpdated) || []
	const result = { ...section }
	tabUpdatedIds.forEach((tabId) => {
		if (section[tabId]) {
			result[tabId] = updateObjWithKeys(section[tabId], sectionUpdated[tabId], [
				{ key: 'hasMainLine', type: 'boolean' },
				{ key: 'mainLineIds', allowFalsy: false },
				{ key: 'oddIds', allowFalsy: false },
			])
		} else {
			result[tabId] = sectionUpdated[tabId]
		}
	})
	return result
}

/**
 *
 * @param {string} homeTeamType
 * @param {string} participants
 * @returns {string}
 */
export const generateEventName = (homeTeamType, participants) => {
	if (isEmpty(participants)) return ''
	const homeTeam = participants?.find((team) => team.type === TEAM_TYPE.HOME)
	const awayTeam = participants?.find((team) => team.type === TEAM_TYPE.AWAY)
	return homeTeamType === 0
		? `${homeTeam?.name} vs ${awayTeam?.name}`
		: `${awayTeam?.name} vs ${homeTeam?.name}`
}

export const removeGameNameInLeagueName = (
	leagueName = '',
	prefixGameName = '',
) => {
	const leagueTemp = leagueName?.replaceAll('–', '-')
	if (!leagueTemp?.includes('-')) {
		return leagueTemp
	}

	// remove the game name prefix
	const splitResult = leagueTemp?.split('-')
	if (prefixGameName === splitResult[0]?.trim()) {
		return splitResult?.splice(1)?.join('-')?.trim()
	}
	return leagueName
}

export const removeGameNameInEsportMatchup = (leagueName = '') => {
	if (!isString(leagueName)) return ''
	const splitNameArray = leagueName.split('-')
	splitNameArray.shift()
	const leagueNameResult = splitNameArray.join('-')
	return leagueNameResult.trim()
}

export const removeGameCodeInLeagueCode = (leagueCode = '', gameCode = '') => {
	if (!gameCode) {
		return leagueCode
	}
	return leagueCode.replace(`${gameCode}-`, '')
}

export const processEventDetailResponse = async ({
	storedEventDetail,
	locale,
	oddsType,
	eventId,
	baseQuery,
	endpoint,
}) => {
	try {
		const isResetVersion =
			storedEventDetail?.lastLocale !== locale ||
			storedEventDetail?.lastOddsType !== oddsType ||
			storedEventDetail?.lastEventId !== eventId
		const versionParam = isResetVersion ? 0 : storedEventDetail?.version
		const specialVersionParam = isResetVersion
			? 0
			: storedEventDetail?.specialVersion
		const response = await baseQuery({
			endpoint,
			method: 'get',
			params: {
				eventId,
				oddsType,
				version: versionParam,
				specialVersion: specialVersionParam,
			},
		})
		const { data: eventDetailResponse } = response
		const availableUpdateData = getAvailableValues(eventDetailResponse, [
			'normal',
			'corners',
			'bookings',
			'kills',
			'alternateLines',
			'setMarkets',
			'gameMarkets',
			'specials',
			'matchMarkets',
			'match180Markets',
			'pointsMarkets',
		])
		if (
			eventDetailResponse?.version === 0 &&
			!availableUpdateData &&
			eventDetailResponse.refreshAll
		) {
			let result = {
				...storedEventDetail,
				bannerData: storedEventDetail?.bannerData,
				eventId,
			}
			result.bannerData.sportId = null
			result.action = EVENT_DETAIL_API_ACTION.END_EVENT
			return { data: result }
		}
		if (versionParam === 0) {
			const data = createEventDetailData(eventDetailResponse, eventId) || {}
			data.action = EVENT_DETAIL_API_ACTION.INIT
			data.lastOddsType = oddsType
			data.lastLocale = locale
			data.lastEventId = eventId
			data.eventId = eventId
			return { data }
		}
		if (versionParam > 0) {
			let result = {
				tab: storedEventDetail?.tab,
				odd: storedEventDetail?.odd,
				section: storedEventDetail?.section,
				tabList: storedEventDetail?.tabList,
				bannerData: storedEventDetail?.bannerData,
				version: eventDetailResponse?.version || storedEventDetail?.version,
				specialVersion:
					eventDetailResponse?.specialVersion ||
					storedEventDetail?.specialVersion,
				lastOddsType: oddsType,
				lastLocale: locale,
				lastEventId: eventId,
				lastOddUpdatedIds: storedEventDetail?.lastOddUpdatedIds || [],
				action: EVENT_DETAIL_API_ACTION.NONE,
				eventId,
			}
			if (
				result.bannerData?.sportId === SPORT_NAME.soccer &&
				eventDetailResponse?.normal?.runningState
			) {
				result.bannerData = updateBannerDataLiveSoccer({
					oddBanner: result.bannerData,
					normal: eventDetailResponse?.normal,
				})
			}

			if (result?.lastOddUpdatedIds.length) {
				result.lastOddUpdatedIds.forEach((oddId) => {
					if (result.odd?.[oddId]?.status) {
						result.odd[oddId].status = ODD_STATUS.NONE
					}
				})
				result.lastOddUpdatedIds = []
			}
			if (availableUpdateData && eventDetailResponse?.refreshAll) {
				const { tabList, tab, section, odd, lastOddUpdatedIds } =
					updateAllEventDetail({
						availableUpdateData,
						bannerData: result?.bannerData || {},
						odd: result.odd,
					})
				result.odd = odd || {}
				result.tab = tab || {}
				result.section = section || {}
				result.tabList = tabList || []
				result.lastOddUpdatedIds = lastOddUpdatedIds
				result.action = EVENT_DETAIL_API_ACTION.UPDATE_ALL
				return { data: result }
			}

			if (availableUpdateData && !eventDetailResponse?.refreshAll) {
				const { tab, section, odd, lastOddUpdatedIds, tabList } =
					updateTabsEventDetail({
						availableUpdateData,
						bannerData: result?.bannerData || {},
						tabList: result.tabList,
						odd: result.odd,
						tab: result.tab,
						section: result.section,
					})
				result.odd = odd || {}
				result.tab = tab || {}
				result.section = section || {}
				result.lastOddUpdatedIds = lastOddUpdatedIds
				result.tabList = tabList
				result.action = EVENT_DETAIL_API_ACTION.UPDATE_TABS
				return { data: result }
			}

			if (
				eventDetailResponse?.update?.length ||
				eventDetailResponse?.specialsUpdate?.length
			) {
				result.action = EVENT_DETAIL_API_ACTION.UPDATE_ODDS
				if (eventDetailResponse?.update?.length) {
					const { odd, lastOddUpdatedIds } = createUpdatedPeriodOdd({
						updatedResponse: eventDetailResponse.update,
						sportId: result?.bannerData?.sportId,
						odd: storedEventDetail?.odd,
					})
					result.odd = { ...result.odd, ...odd }
					result.lastOddUpdatedIds = lastOddUpdatedIds
				}
				if (eventDetailResponse?.specialsUpdate?.length) {
					const { odd, lastOddUpdatedIds } = createUpdatedSpecialOdd({
						updatedResponse: eventDetailResponse.specialsUpdate,
						odd: storedEventDetail?.odd,
					})
					result.odd = { ...result.odd, ...odd }
					result.lastOddUpdatedIds = [
						...result.lastOddUpdatedIds,
						...lastOddUpdatedIds,
					]
				}
				return { data: result }
			}
			return { data: result }
		}
	} catch (err) {
		console.warn(err)
	}
}

export const checkIsMultiviewPage = () => {
	const pathname = window.location.pathname
	const { routes } = getCurrentRouterProvider()
	return !!matchPath(`/${routes.liveCentreMultiview}`, pathname)
}

export const createSelectionData = ({
	oddData,
	oddsFormat,
	displayName,
	mainPages = BET_LOCATION_TRACKING.UNKNOWN,
}) => {
	const {
		participants = [],
		bannerData,
		id,
		lineId,
		teamType,
		value,
		homeTeamType,
		awayTeamType,
		contestantName,
		type,
		parlayRestriction,
		rotNum,
		isSpecial,
	} = oddData
	const {
		eventName,
		leagueId,
		leagueName,
		sportId,
		sportName,
		sportCode,
		resultingUnit,
	} = bannerData
	const [eventId, , betType, , , handicap] = id.split('|')
	const selectionId = `${lineId}|${id}|${teamType}`
	const newParticipants = cloneDeep(participants)

	const betLocationTracking = addBetTracking({
		mainPages,
	})

	let result = {
		oddsFormat,
		eventId,
		betType,
		handicap,
		teamType,
		selectionId,
		odds: value,
		oddsId: id,
		eventName,
		leagueId,
		leagueName,
		sport: sportCode,
		sportId,
		sportName,
		participants: newParticipants,
		contestantName,
		seasonId: '',
		oddsSelectionsType: type,
		homeTeamType,
		awayTeamType,
		selectedAt: new Date().valueOf(),
		parlayRestriction,
		rotNum,
		gradingUnit: resultingUnit,
		isSpecial,
		inplay: bannerData?.isLive,
		betLocationTracking,
	}

	if (betType === '99') {
		result.betType = 9
		result.specialName = result.contestantName
		result.contestantName = displayName
	}

	return result
}

export const createSelectionName = ({
	keyTranslates,
	formatMessage,
	defaultMessage,
}) => {
	if (keyTranslates) {
		const { draw, under, over, subKeyTranslate, points } = keyTranslates
		if (draw) {
			return formatMessage({
				id: keyTranslates.draw,
				defaultMessage: defaultMessage,
			})
		}
		const firstKey = over || under
		if (firstKey && subKeyTranslate) {
			return `${formatMessage({
				id: firstKey,
				defaultMessage: firstKey,
			})} ${points} ${formatMessage({
				id: subKeyTranslate,
				defaultMessage: subKeyTranslate,
			})}`
		}
		if (firstKey && !subKeyTranslate) {
			return `${formatMessage({
				id: firstKey,
				defaultMessage: firstKey,
			})} ${points}`
		}
	}
	return defaultMessage
}
