import {
	LIVE_STREAM_ACTION_TYPE,
	LIVE_STREAM_ELEMENT_ID,
} from '../../constants/liveStream'
import { buildRouteUrl } from '../Url'
import { SPORT_ID, SPORT_NAME } from 'constants'

export const initMiniPlayerDOMEvent = ({
	handleShowMiniPlayer,
	handleCloseMiniPlayer,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.SHOW_MINI_PLAYER,
		handleShowMiniPlayer,
	)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.CLOSE_MINI_PLAYER,
		handleCloseMiniPlayer,
	)
}

export const removeMiniPlayerDOMEvent = ({
	handleShowMiniPlayer,
	handleCloseMiniPlayer,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.removeEventListener(
		LIVE_STREAM_ACTION_TYPE.SHOW_MINI_PLAYER,
		handleShowMiniPlayer,
	)
	root.removeEventListener(
		LIVE_STREAM_ACTION_TYPE.CLOSE_MINI_PLAYER,
		handleCloseMiniPlayer,
	)
}

export const mainPlayerTriggerMiniPlayer = ({
	navigate,
	routes,
	mainEventData,
	liveProps,
}) => {
	const { url } = liveProps.status
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(LIVE_STREAM_ACTION_TYPE.SHOW_MINI_PLAYER, {
		detail: { streamUrl: url, mainEventData },
	})
	root.dispatchEvent(event)
}

export const triggerCloseMiniPlayer = () => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(LIVE_STREAM_ACTION_TYPE.CLOSE_MINI_PLAYER)
	root.dispatchEvent(event)
}

export const initLiveStreamPlayerDOMEvent = ({ handleToggleLiveStreamTab }) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.TOGGLE_LIVE_STREAM_TAB,
		handleToggleLiveStreamTab,
	)
}

export const removeLiveStreamPlayerDOMEvent = ({
	handleToggleLiveStreamTab,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.removeEventListener(
		LIVE_STREAM_ACTION_TYPE.TOGGLE_LIVE_STREAM_TAB,
		handleToggleLiveStreamTab,
	)
}

export const triggerToggleLiveStreamTab = ({ isExpand, isLSTab }) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(
		LIVE_STREAM_ACTION_TYPE.TOGGLE_LIVE_STREAM_TAB,
		{
			detail: { isExpand, isLSTab },
		},
	)
	root.dispatchEvent(event)
}

export const initVideoPlayerDOMEvent = ({
	handleShowVideoPlayerIframe,
	handleCloseVideoPlayerIframe,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.SHOW_VIDEO_PLAYER,
		handleShowVideoPlayerIframe,
	)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.CLOSE_VIDEO_PLAYER,
		handleCloseVideoPlayerIframe,
	)
}

export const removeVideoPlayerDOMEvent = ({
	handleShowVideoPlayerIframe,
	handleCloseVideoPlayerIframe,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.SHOW_VIDEO_PLAYER,
		handleShowVideoPlayerIframe,
	)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.CLOSE_VIDEO_PLAYER,
		handleCloseVideoPlayerIframe,
	)
}

export const triggerShowVideoPlayer = ({
	mainEventData,
	isActive,
	liveStreamData,
}) => {
	if (!window?.env?.switchLiveStreamNoDelayEnabled) return
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(LIVE_STREAM_ACTION_TYPE.SHOW_VIDEO_PLAYER, {
		detail: { mainEventData, isActive, liveStreamData },
	})
	root.dispatchEvent(event)
}

export const triggerCloseVideoPlayer = ({
	isActive,
	closeByTab,
	closeByBtn,
}) => {
	if (!window?.env?.switchLiveStreamNoDelayEnabled) return
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(LIVE_STREAM_ACTION_TYPE.CLOSE_VIDEO_PLAYER, {
		detail: { isActive, closeByTab, closeByBtn },
	})
	root.dispatchEvent(event)
}
