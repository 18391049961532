import { WAGER_MYBET_MAP } from 'constants/common'
import { isNil, get } from 'lodash'
import { convertStringToJSON } from './object'

export const formatSeparators = (handicap) => {
	const dot = '.'
	const comma = ','
	const rgx = /(\d+)(\d{3})/
	let result = handicap.toString()
	const dpos = result.lastIndexOf(dot)
	let nStrEnd = ''
	if (dpos !== -1) {
		nStrEnd = `${dot}${result.substring(dpos + 1, result.length)}`
		result = result.substring(0, dpos)
	}

	while (rgx.test(result)) {
		result = result.replace(rgx, '$1' + comma + '$2')
	}
	return `${result}${nStrEnd}`
}

export const sum = (...args) => [...args].reduce((a, b) => a + b, 0).toFixed(2)

export const summedValue = (groupedBets, key) => {
	return sum(...groupedBets.map((item) => item[WAGER_MYBET_MAP[key]]))
}

export const getConfigValue = (configKey, defaultValue) => {
	if (!window?.env?.isB2B) return defaultValue
	return window?.env?.[configKey]
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export const toCapitalCase = (str = '') => {
	if (typeof str === 'string' || !str) {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}
	return ''
}

/**
 *
 * @param {Object} obj
 * @param {[string]} listKey
 * @returns {Object | null}
 */
export const getAvailableValues = (obj = {}, listKey = []) => {
	let result = {}
	const availableKeys = listKey.filter((key) => !isNil(obj[key]))
	availableKeys.forEach((key) => {
		result[key] = obj[key]
	})
	return availableKeys?.length ? result : null
}

/**
 *
 * @param {string} key
 * @param {Object} value
 * @returns {boolean}
 */
export const setLocalStorage = (key, value) => {
	try {
		localStorage.setItem(key, JSON.stringify(value))
		return true
	} catch (error) {
		console.error(error)
		return false
	}
}
/**
 *
 * @param {string} key
 * @returns {any|null}
 */
export const getLocalStorage = (key) => {
	try {
		const storedValue = localStorage.getItem(key)
		return storedValue ? JSON.parse(storedValue) : null
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 *
 * @param {string} key
 * @returns {boolean}
 */
export const removeLocalStorage = (key) => {
	try {
		localStorage.removeItem(key)
		return true
	} catch (error) {
		console.error(error)
		return false
	}
}

/**
 * Build URL friendly slugs based on a phrase.
 * eg:  LEAGUE OF LEGENDS -> league-of-legends
 */
export const slugify = (str) =>
	str
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w\\-]+/g, '') // Remove all non-word chars
		.replace(/\\-\\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text

export const deepCopy = (data) => JSON.parse(JSON.stringify(data))

export const scrollWindowTo = (target, duration) => {
	// we need to try both elements for a browser hack;
	// chrome uses html, safari uses body. go figure!
	const html = document.getElementsByTagName('html')[0]
	const body = document.body
	const element = html.scrollTop !== 0 ? html : body
	const distanceToTop = target - element.scrollTop
	scrollTo(element, distanceToTop, duration)
}

export const scrollTo = (element, target, duration) => {
	if (duration <= 0 || element.scrollTop === target) return
	setTimeout(() => {
		const perTick = (target / duration) * 10
		element.scrollTop = element.scrollTop + perTick
		scrollTo(element, target, duration - 10)
	}, 10)
}

export const getB2BParentDomain = (isB2B) => {
	if (isB2B) {
		try {
			return get(window, 'location.ancestorOrigins[0]', document.referrer || '')
		} catch (err) {
			return document.referrer || ''
		}
	}
	return ''
}

export const getHelpPageLocaleURL = (localeCode) => {
	const helpPageLanguages = convertStringToJSON(window?.env?.helpPageSupportingLanguages) || {}
	return helpPageLanguages[localeCode] || 'en_US'
}
