import { BetType } from 'apps/components/OddPage/oddPageUlti'
import {
	generateEventName,
	getTeam,
	sumObject,
	updateOddEventDetail,
} from '../Events'
import {
	EVENT_DETAIL_IDS_KEY,
	EVENT_ESPORT_HUB_DETAIL_IDS_KEY,
	ODD_STATUS,
	ODD_TYPE,
	SHORT_NAME,
	SIDE_BAR_LIVE_EVENT_API_ACTION,
	SPORT_ID,
	SPORT_NAME,
} from '../../constants'
import { appIntl } from 'apps/components/LocalizedApp/IntGlobalProvider'
import './typedef.js'
import { getGameNameByGameCode } from '../Games'
import { getIconGameName } from '../LeftMenu'
import { isStandardEsportsHubPath } from '../Url'

/**
 *
 * @param eventResponse
 * @param league
 * @param primaryMarketType
 * @param sportId
 * @returns {{event: {}, eventIds: [], odd: {}}}
 */
const createSideBarEvent = ({
	eventResponse,
	league,
	primaryMarketType,
	sportId,
}) => {
	const result = {
		event: {},
		eventIds: [],
		odd: {},
	}
	result.eventIds = eventResponse?.map((event) => {
		if (!event?.id) return null
		const bannerData = createLiveEventBannerData({
			event,
			league,
			sportId,
		})
		const { oddIds, odd } = createLiveEventOdds({
			event,
			sportId,
			primaryMarketType,
			bannerData,
		})
		result.event[event.id] = {
			bannerData,
			oddIds,
		}
		result.odd = {
			...result.odd,
			...odd,
		}
		return event.id
	})
	return { ...result }
}

/**
 *
 * @param eventUpdatedResponse
 * @param league
 * @param primaryMarketType
 * @param sportId
 * @returns {{oddUpdated: {}, eventUpdated: {}}}
 */
const createSideBarEventUpdated = ({
	eventUpdatedResponse = [],
	league,
	primaryMarketType,
	sportId,
}) => {
	const result = {
		eventUpdated: {},
		oddUpdated: {},
	}
	eventUpdatedResponse?.forEach((eventUpdated) => {
		const { event, odd } = createSideBarEvent({
			eventResponse: eventUpdated?.events,
			league: league[eventUpdated?.id],
			primaryMarketType,
			sportId,
		})
		result.eventUpdated = { ...result.event, ...event }
		result.oddUpdated = { ...result.odd, ...odd }
	})
	return result
}

/**
 *
 * @param {LiveEventListResponse} sideBarLiveEventResponse
 * @param {string} primaryMarketType
 * @returns {SideBarEventList} result
 */
export const createSideBarLiveEventList = ({
	sideBarLiveEventResponse,
	primaryMarketType,
}) => {
	const result = {
		league: {},
		leagueIds: [],
		event: {},
		odd: {},
	}
	result.leagueIds = sideBarLiveEventResponse?.leagues?.map((league) => {
		if (!league?.id) return null
		const { eventIds, event, odd } = createSideBarEvent({
			eventResponse: league?.events,
			league,
			primaryMarketType,
			sportId: sideBarLiveEventResponse?.sportId,
		})
		result.event = { ...result.event, ...event }
		result.odd = { ...result.odd, ...odd }
		result.league[league.id] = {
			...league,
			totalEvents: eventIds.length,
		}
		result.league[league.id].eventIds = eventIds
		delete result.league[league.id].events
		return league.id
	})
	return result
}

/**
 *
 * @param {number|string} sportId
 * @param {Sport} sport
 * @returns {overUnder | handicap | moneyLine}
 */
export const getPrimaryMarketType = ({ sport }) => {
	switch (sport?.primaryMarketType) {
		case 'moneyline': {
			return 'moneyLine'
		}
		case 'spread': {
			return 'handicap'
		}
		case 'overunder': {
			return 'overUnder'
		}
		default: {
			return 'moneyLine'
		}
	}
}

/**
 *
 * @param event
 * @param league
 * @param sportId
 * @returns {{sportCode: string, awayTeam, homeTeamType: *, leagueName: *, sportId, resultingUnit: *, isLive: boolean, awayTeamType: *, leagueId: *, eventName: string, homeTeam, runningState: (string|string|*), sportName: *, isSwapHomeAway: boolean, participants: *}}
 */
const createLiveEventBannerData = ({ event, league, sportId }) => {
	const { homeTeam, awayTeam } = getTeam(event?.participants)
	return {
		eventName: generateEventName(event?.homeTeamType, event?.participants),
		leagueId: league?.id,
		leagueName: league?.name,
		sportId: sportId,
		sportName: appIntl().formatMessage({
			id: `sport_name.${SPORT_ID[sportId]}`,
			defaultMessage: '',
		}),
		sportCode: SPORT_ID?.[sportId],
		resultingUnit: event?.resultingUnit,
		homeTeam,
		homeTeamType: event?.homeTeamType,
		homeTeamScore: sumObject(homeTeam.score),
		homeTeamRedCard: sumObject(homeTeam.redCards),
		awayTeam,
		awayTeamType: event?.awayTeamType,
		awayTeamScore: sumObject(awayTeam.score),
		awayTeamRedCard: sumObject(awayTeam.redCards),
		participants: event?.participants,
		isLive: !!event?.runningState,
		isSwapHomeAway: event?.homeTeamType === 1,
		runningState: event?.runningState,
	}
}

/**
 *
 * @param event
 * @param primaryMarketType
 * @param bannerData
 * @returns {{oddIds: string[], columns: number, odd: {string?: OddObj}}|{oddIds: [string,string,string]|[string,string], columns: number, odd: {}}}
 */
const createLiveEventOdds = ({ event, primaryMarketType, bannerData }) => {
	const selectedMarket = event?.periods?.[0]?.[primaryMarketType]
	switch (primaryMarketType) {
		case 'moneyLine': {
			return {
				...createSideBarMoneyLineOdd({
					dataOdd: selectedMarket,
					eventId: event.id,
					periodValue: 0,
					parlayRestriction: event.parlayRestriction,
					rotNum: event.rotNum,
					bannerData,
				}),
			}
		}
		default: {
			return {
				...createSideBarHandicapOdd({
					dataOdd: selectedMarket,
					eventId: event.id,
					periodValue: 0,
					parlayRestriction: event.parlayRestriction,
					rotNum: event.rotNum,
					bannerData,
				}),
			}
		}
	}
}

/**
 *
 * @param dataOdd
 * @param eventId
 * @param periodValue
 * @param bannerData
 * @param parlayRestriction
 * @param rotNum
 * @returns {{oddIds: ((string|string)[]|string[]), columns: (number|number), odd: {}}}
 */
const createSideBarMoneyLineOdd = ({
	dataOdd = {},
	eventId,
	periodValue,
	bannerData,
	parlayRestriction,
	rotNum,
}) => {
	const odd = {}
	const betTypeValue = BetType.THE_1X2
	const columns = 3
	const oddHomeId = `${eventId}|${periodValue}|${betTypeValue}|0|0|0`
	const oddAwayId = `${eventId}|${periodValue}|${betTypeValue}|1|0|0`
	const { homePrice, awayPrice, drawPrice, lineId, offline } = dataOdd || {}
	const { homeTeamType, awayTeamType, participants } = bannerData || {}
	const temp = {
		keyTranslates: null,
		type: ODD_TYPE.NORMAL,
		lineId,
		isAlt: false,
		offline,
		awayTeamType,
		homeTeamType,
		participants,
		status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
		parlayRestriction,
		rotNum,
		eventId,
	}
	let oddDrawId = ''
	odd[oddHomeId] = {
		...temp,
		id: oddHomeId,
		defaultMessage: participants?.[0]?.name,
		value: homePrice,
		teamType: homeTeamType,
		offline,
		shortName: bannerData.isSwapHomeAway ? SHORT_NAME.SECOND : SHORT_NAME.FIRST,
	}
	odd[oddAwayId] = {
		...temp,
		id: oddAwayId,
		defaultMessage: participants?.[1]?.name,
		value: awayPrice,
		offline,
		teamType: awayTeamType,
		shortName: bannerData.isSwapHomeAway ? SHORT_NAME.FIRST : SHORT_NAME.SECOND,
	}
	if (typeof drawPrice === 'string') {
		oddDrawId = `${eventId}|${periodValue}|${betTypeValue}|2|0|0`
		odd[oddDrawId] = {
			...temp,
			id: oddDrawId,
			defaultMessage: 'Draw',
			keyTranslates: { draw: 'event_detail.draw' },
			value: drawPrice,
			teamType: 2,
			shortName: 'X',
		}
	}
	return {
		odd,
		oddIds: oddDrawId
			? [oddHomeId, oddDrawId, oddAwayId]
			: [oddHomeId, oddAwayId],
		columns: oddDrawId ? columns : columns - 1,
	}
}

/**
 *
 * @param config
 * @param dataOdd
 * @param {number} eventId
 * @param {string|number} periodValue
 * @param {BannerData} bannerData
 * @param parlayRestriction
 * @param rotNum
 * @returns {{odd: {[string]: OddObj}, oddIds: [string], columns: number}}
 */
const createSideBarHandicapOdd = ({
	dataOdd,
	eventId,
	periodValue,
	bannerData,
	parlayRestriction,
	rotNum,
}) => {
	const odd = {}
	const oddIds = []
	const betTypeValue = BetType.HANDICAP
	const columns = 2
	const { homeTeamType, awayTeamType, participants } = bannerData || {}
	dataOdd.forEach(
		({
			isAlt,
			homeSpread,
			awaySpread,
			homeOdds,
			awayOdds,
			lineId,
			offline,
		}) => {
			if (isAlt) return
			const oddHomeId = `${eventId}|${periodValue}|${betTypeValue}|0|0|${homeSpread}`
			const oddAwayId = `${eventId}|${periodValue}|${betTypeValue}|1|0|${awaySpread}`
			const temp = {
				keyTranslates: null,
				type: ODD_TYPE.NORMAL,
				lineId,
				isAlt,
				offline,
				awayTeamType,
				homeTeamType,
				participants,
				parlayRestriction,
				rotNum,
				status: offline ? ODD_STATUS.OFFLINE : ODD_STATUS.NONE,
				eventId,
			}
			odd[oddHomeId] = {
				...temp,
				id: oddHomeId,
				defaultMessage: homeSpread,
				value: homeOdds,
				teamType: homeTeamType,
				shortName: homeSpread,
			}
			odd[oddAwayId] = {
				...temp,
				id: oddAwayId,
				defaultMessage: awaySpread,
				value: awayOdds,
				teamType: awayTeamType,
				shortName: awaySpread,
			}
			oddIds.push(oddHomeId)
			oddIds.push(oddAwayId)
		},
	)
	return { odd, oddIds, columns }
}

/**
 *
 * @param eventUpdated
 * @param event
 * @returns {{event}}
 */
const updateEventSideBar = ({ eventUpdated, event }) => {
	const eventUpdatedIds = Object.keys(eventUpdated)
	const result = { ...event }
	eventUpdatedIds.forEach((eventId) => {
		if (!event[eventId]) return
		result[eventId] = {
			...event[eventId],
			...eventUpdated[eventId],
		}
	})
	return { event: result }
}

/**
 *
 * @param storedSideBarLiveEvent
 * @param locale
 * @param oddsType
 * @param baseQuery
 * @param endpoint
 * @param sportId
 * @returns {Promise<{data: {lastOddsType, sportId, league: *, action: string, lastOddUpdatedIds: (*|*[]), event: *, sport: *, leagueIds: ([]|*|[*]), version: (number|*), lastSelectedSportId, odd: *, lastLocale}}|{data: {lastOddsType: *, sportId: *, league: *, action: string, lastOddUpdatedIds, event: *, sport: *, leagueIds: *, version: number, lastSelectedSportId: *, odd: *, lastLocale: *}}>}
 */
export const processSideBarLiveEventResponse = async ({
	storedSideBarLiveEvent,
	locale,
	oddsType,
	baseQuery,
	endpoint,
	sportId,
}) => {
	try {
		const isResetVersion =
			storedSideBarLiveEvent?.lastLocale !== locale ||
			storedSideBarLiveEvent?.lastOddsType !== oddsType ||
			storedSideBarLiveEvent?.lastSelectedSportId !== sportId
		const versionParam = isResetVersion ? 0 : storedSideBarLiveEvent?.version
		const primaryMarketType =
			storedSideBarLiveEvent.sport?.[sportId]?.primaryMarketType
		let result = {
			sport: storedSideBarLiveEvent?.sport,
			league: storedSideBarLiveEvent?.league,
			leagueIds: storedSideBarLiveEvent?.leagueIds,
			event: storedSideBarLiveEvent?.event,
			odd: storedSideBarLiveEvent?.odd,
			version: versionParam,
			lastOddsType: oddsType,
			lastLocale: locale,
			lastSelectedSportId: sportId,
			lastOddUpdatedIds: storedSideBarLiveEvent?.lastOddUpdatedIds || [],
			action: SIDE_BAR_LIVE_EVENT_API_ACTION.NONE,
			sportId,
		}

		const { data: sideBarLiveEventResponse } = await baseQuery({
			endpoint,
			method: 'get',
			params: {
				sportId: sportId,
				isLive: true,
				isHlE: false,
				oddsType: oddsType,
				language: locale,
				eventType: 0,
				onlyMainLine: true,
				version: versionParam,
			},
		})

		result.version = sideBarLiveEventResponse?.version || result.version

		if (versionParam === 0) {
			const sideBarLiveEventData =
				createSideBarLiveEventList({
					sideBarLiveEventResponse,
					primaryMarketType,
				}) || {}
			result = {
				...result,
				...sideBarLiveEventData,
				action: SIDE_BAR_LIVE_EVENT_API_ACTION.INIT,
			}
			if (result.sport[sportId]) {
				result.sport[sportId].leagueIds = sideBarLiveEventData.leagueIds || []
			}
			return { data: result }
		}

		result.lastOddUpdatedIds?.forEach((oddId) => {
			if (result.odd?.[oddId]?.status) {
				result.odd[oddId].status = ODD_STATUS.NONE
			}
		})
		result.lastOddUpdatedIds = []

		if (sideBarLiveEventResponse?.refreshAll) {
			const newSideBarLiveEventData = createSideBarLiveEventList({
				sideBarLiveEventResponse,
				primaryMarketType,
			})
			const { odd, lastOddUpdatedIds } = updateOddEventDetail({
				odd: result.odd,
				oddUpdated: newSideBarLiveEventData.odd,
				isUpdateAll: true,
			})
			result.odd = odd
			result.league = newSideBarLiveEventData.league || {}
			result.event = newSideBarLiveEventData.event || {}
			result.leagueIds = newSideBarLiveEventData.leagueIds || []
			result.lastOddUpdatedIds = lastOddUpdatedIds
			result.action = SIDE_BAR_LIVE_EVENT_API_ACTION.UPDATE_ALL
			if (result.sport[sportId]) {
				result.sport[sportId].leagueIds = newSideBarLiveEventData.leagueIds || []
			}
			return { data: result }
		}

		if (
			!sideBarLiveEventResponse?.refreshAll &&
			sideBarLiveEventResponse?.leagues?.length
		) {
			const { event: eventUpdated, odd: oddUpdated } =
				createSideBarLiveEventList({
					sideBarLiveEventResponse,
					primaryMarketType,
				})
			const { event } = updateEventSideBar({
				eventUpdated,
				event: result.event,
			})
			const { odd, lastOddUpdatedIds } = updateOddEventDetail({
				odd: result.odd,
				oddUpdated,
				isUpdateAll: false,
			})
			result.odd = odd
			result.event = event
			result.lastOddUpdatedIds = lastOddUpdatedIds
			result.action = SIDE_BAR_LIVE_EVENT_API_ACTION.UPDATE_LEAGUE
			return { data: result }
		}

		if (
			!sideBarLiveEventResponse?.refreshAll &&
			sideBarLiveEventResponse?.update?.length
		) {
			const { eventUpdated, oddUpdated } = createSideBarEventUpdated({
				sideBarLiveEventResponse,
				primaryMarketType,
				league: result.league,
			})
			const { event } = updateEventSideBar({
				eventUpdated,
				event: result.event,
			})
			const { odd, lastOddUpdatedIds } = updateOddEventDetail({
				odd: result.odd,
				oddUpdated: oddUpdated,
				isUpdateAll: false,
			})
			result.odd = odd
			result.event = event
			result.lastOddUpdatedIds = lastOddUpdatedIds
			result.action = SIDE_BAR_LIVE_EVENT_API_ACTION.UPDATE_EVENT
			return { data: result }
		}
		return { data: result }
	} catch (err) {
		console.warn(err)
	}
}

const createSideBarGameData = ({ sideBarLiveEventData }) => {
	return sideBarLiveEventData?.data?.leagueIds?.reduce((acc, leagueId) => {
		const leagueData = sideBarLiveEventData.data?.league?.[leagueId]
		const gameCode = leagueData?.gameCode
		if (gameCode) {
			acc[gameCode] = {
				...sideBarLiveEventData.data?.sport?.[SPORT_NAME.esports],
				englishName: getGameNameByGameCode(gameCode),
				name: getGameNameByGameCode(gameCode),
				id: gameCode,
				iconName: getIconGameName(gameCode),
				gameCode,
				leagueIds: acc[gameCode]?.leagueIds
					? [...acc[gameCode].leagueIds, leagueId]
					: [leagueId],
			}
		}
		return acc
	}, {})
}

const createEsportsMenuSideBarData = ({ sideBarLiveEventData }) => {
	const result = {
		league: sideBarLiveEventData.data.league,
		sport: sideBarLiveEventData.data.sport,
	}

	result.league = {
		...result.league,
		...sideBarLiveEventData.data.gameIds?.reduce(
			(acc, gameId, currentIndex) => {
				const gameData = sideBarLiveEventData.data.game[gameId]

				const { totalEvents, eventIds } = gameData.leagueIds.reduce(
					(acc, leagueId) => {
						const leagueData = sideBarLiveEventData.data.league[leagueId]
						acc.totalEvents += leagueData.eventIds.length
						acc.eventIds = [...acc.eventIds, ...leagueData.eventIds]
						return acc
					},
					{ totalEvents: 0, eventIds: [] },
				)
				acc[gameId] = {
					id: gameId,
					name: gameData.name,
					leagueCode: gameData.gameCode,
					gameCode: gameData.gameCode,
					container: '',
					seqNo: currentIndex,
					totalEvents,
					eventIds,
				}
				return acc
			},
			{},
		),
	}
	if (result.sport?.[SPORT_NAME.esports]) {
		result.sport[SPORT_NAME.esports].leagueIds =
			sideBarLiveEventData.data.gameIds
	}

	return result
}

export const processSideBarLiveEventEsportsHubResponse = async ({
	storedSideBarLiveEvent,
	locale,
	oddsType,
	baseQuery,
	endpoint,
	sportId,
}) => {
	try {
		const result = await processSideBarLiveEventResponse({
			storedSideBarLiveEvent,
			locale,
			oddsType,
			baseQuery,
			endpoint,
			sportId,
		})

		result.data.game = createSideBarGameData({ sideBarLiveEventData: result })
		result.data.gameIds = Object.keys(result.data.game)
		const { league, sport } = createEsportsMenuSideBarData({
			sideBarLiveEventData: result,
		})
		result.data.league = league
		result.data.sport = sport
		return result
	} catch (err) {
		console.warn(err)
	}
}

export const getEventsIdsKey = () => {
	return isStandardEsportsHubPath()
		? EVENT_ESPORT_HUB_DETAIL_IDS_KEY
		: EVENT_DETAIL_IDS_KEY
}
