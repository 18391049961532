import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from './rtkApi'
import {
	EVENT_DETAIL_IDS_KEY,
	EVENT_ESPORT_HUB_DETAIL_IDS_KEY,
	SPORT_NAME,
	WS_DESTINATION_TYPE,
	WS_MESSAGE_TYPE,
} from 'constants'
import { SPORTS_SERVICES } from 'constants/servicesApi'
import Futures from 'apps/types/Futures'
import {
	createEsportsGamesMenu,
	createFavouriteMenu,
	createFavouriteTotalEventsMap,
	createLeagueMenu,
	createSportMenu,
} from 'services/LeftMenu'
import {
	createListLeagues,
	createListLeaguesByGames,
} from 'services/SportLeagues'
import { createEsportsHubGames, createListGames } from 'services/Games'
import { processEventDetailResponse } from 'services/Events'
import { cloneDeep, get, isFunction, omit, sortBy } from 'lodash'
import {
	parseMatchUpResponse,
	parseMatchUpResponseTeamTotal,
} from 'services/MatchUp'
import { parseWsBody } from 'services/WebSocket'
import { updateFavouriteTotalEvents } from '../slices/FavouriteSports'
import {
	buildWebSocketURL,
	getCurrentRouterProvider,
	isStandardEsportsHubMultiviewPath,
} from 'services/Url'
import {
	processSideBarLiveEventEsportsHubResponse,
	processSideBarLiveEventResponse,
} from 'services/Multiview'

export const SPORT_PATH = 'sportServices'
const {
	BASE,
	COMPACT_OUTRIGHT,
	COMPACT_ESPORTS_OUTRIGHT,
	ESPORTS_GAMES,
	LEAGUES,
	LEFT_MENU,
	ODDS,
	ODDS_EVENT,
	ODDS_LEAGUE,
	ODDS_MATCHUPS,
	ODDS_PERIODS,
	SEARCH_BY_STRING,
	PARTICIPANT_MATCHUP,
	LIVE_EVENT,
} = SPORTS_SERVICES

export const sportServices = createApi({
	reducerPath: SPORT_PATH,
	baseQuery: axiosBaseQuery(BASE),
	endpoints: () => ({}),
})

export const sportApiQuery = sportServices.injectEndpoints({
	endpoints: (builders) => ({
		getSportLeftMenu: builders.query({
			query: () => ({
				endpoint: LEFT_MENU,
				method: 'get',
			}),
			transformResponse(response) {
				return createSportMenu(response)
			},
		}),
		getSportLeagueLeftMenu: builders.query({
			query: ({ sportId }) => ({
				endpoint: LEFT_MENU,
				params: {
					sportId: sportId,
				},
				method: 'get',
			}),
			transformResponse(response, _, { sportId, globalConfigs }) {
				return createLeagueMenu(response, sportId, globalConfigs)
			},
		}),
		getEsportGamesLeftMenu: builders.query({
			query: () => ({
				endpoint: ESPORTS_GAMES,
				method: 'get',
			}),
			transformResponse(response, _, { globalConfigs }) {
				return createEsportsGamesMenu(response, globalConfigs)
			},
		}),
		getFavouriteLeftMenu: builders.query({
			query: () => ({
				endpoint: getCurrentRouterProvider()?.apiEndpoint?.getFavourite,
				method: 'get',
			}),
			transformResponse(response, _, { globalConfigs }) {
				return createFavouriteMenu(response, globalConfigs)
			},
			keepUnusedDataFor: 0,
		}),
		getFavouriteLeftMenuTotalEvents: builders.query({
			query: ({ leagueIds = '', gameCodes = '', participants = '' }) => ({
				endpoint: getCurrentRouterProvider()?.apiEndpoint?.getFavourite,
				params: {
					leagueIds,
					gameCodes,
					participants,
				},
				method: 'get',
			}),
			onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
				try {
					const response = await queryFulfilled
					return dispatch(
						updateFavouriteTotalEvents(
							createFavouriteTotalEventsMap(response?.data),
						),
					)
				} catch (error) {
					console.error(error)
				}
			},
		}),
		getSportLeagues: builders.query({
			query: ({ sportId }) => ({
				endpoint: LEAGUES,
				params: {
					sportId: sportId,
				},
				method: 'get',
			}),
			transformResponse(response, _, { sportId }) {
				return createListLeagues(response, sportId)
			},
		}),
		getEsportLeaguesByGameCode: builders.query({
			query: ({ sportId, gameCode }) => ({
				endpoint: LEAGUES,
				params: {
					sportId: sportId,
					gameCode: gameCode,
				},
				method: 'get',
			}),
			transformResponse(response, _, { sportId }) {
				return createListLeagues(response, sportId)
			},
		}),
		getSportLeagueByCode: builders.query({
			query: ({ sportId, leagueCode }) => ({
				endpoint: LEAGUES,
				params: {
					sportId: sportId,
					leagueCode: leagueCode,
				},
				method: 'get',
			}),
			transformResponse(response, _, { sportId }) {
				const temp = createListLeagues(response, sportId)
				const key = Object.keys(temp?.league)?.[0]
				return temp?.league?.[key]
			},
			keepUnusedDataFor: 0,
		}),
		getEsportGameByCode: builders.query({
			query: ({ eSportCode }) => ({
				endpoint: ESPORTS_GAMES,
				params: {
					eSportCode: eSportCode,
				},
				method: 'get',
			}),
			transformResponse(response) {
				const temp = createListGames(response)
				const key = Object.keys(temp?.game)?.[0]
				return temp?.game?.[key]
			},
		}),
		getEsportGames: builders.query({
			query: () => ({
				endpoint: ESPORTS_GAMES,
				method: 'get',
			}),
			transformResponse(response, _) {
				return createListGames(response)
			},
		}),
		getEventDetail: builders.query({
			async queryFn(
				{ eventId, oddsType, locale },
				{ getState, signal },
				extraOptions,
				baseQuery,
			) {
				const storedEventDetail = cloneDeep(getState()?.EventDetail)
				return processEventDetailResponse({
					storedEventDetail,
					locale,
					oddsType,
					eventId,
					baseQuery,
					endpoint: ODDS_EVENT,
				})
			},
			keepUnusedDataFor: 0,
		}),
		getMultiviewEventDetail: builders.query({
			async queryFn(
				{ eventId, oddsType, locale },
				{ getState, signal },
				extraOptions,
				baseQuery,
			) {
				const storedEventDetail = cloneDeep(
					getState()?.Multiview?.eventDetail?.[eventId],
				)
				return processEventDetailResponse({
					storedEventDetail,
					locale,
					oddsType,
					eventId,
					baseQuery,
					endpoint: ODDS_EVENT,
				})
			},
			keepUnusedDataFor: 0,
		}),
		getEventNewEuroView: builders.query({
			query: (params) => ({
				endpoint: ODDS,
				params: {
					sportId: params.sportId,
					isLive: params.isLive,
					isHlE: params.isHlE,
					oddsType: params.oddsType,
					version: params.version || 0,
					timeStamp: params.timeStampRTK,
					language: params.language,
					isHomePage: params.isHomePage ? params.isHomePage : '',
					leagueCode: params.leagueCode ? params.leagueCode : '',
					eventType: params.eventType,
					eSportCode: params.gameCode ? params.gameCode : '',
					periodNum: params.periodNum || '',
					participant: params.participant ? params.participant : '',
				},
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					return response || []
				}
				return []
			},
			keepUnusedDataFor: 0,
		}),
		getEventLive: builders.query({
			query: (params) => ({
				endpoint: LIVE_EVENT,
				params: {
					sportIds: params.sportIds,
					eventIds: params.eventIds,
					oddsType: params.oddsType,
					versions: params.versions || 0,
					timeStamp: params.timeStampRTK,
					language: params.language,
					periodNum: params.periodNum,
				},
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					return response || []
				}
				return []
			},
			keepUnusedDataFor: 0,
		}),
		getEventNewEuroViewWebSocket: builders.query({
			queryFn() {
				return { data: null }
			},
			async onCacheEntryAdded(
				arg,
				{ updateCachedData, cacheDataLoaded, cacheEntryRemoved },
			) {
				const destination = arg.isLive
					? WS_DESTINATION_TYPE.LIVE_EURO
					: WS_DESTINATION_TYPE.HLE_EURO

				let socket = null
				try {
					socket = new WebSocket(buildWebSocketURL())
					const socketConnected = new Promise((resolve, reject) => {
						try {
							socket.onerror = (error) => {
								reject(error)
							}

							socket.onopen = (event) => {
								resolve(event)
							}

							socket.addEventListener('open', (event) => {
								resolve(event)
							})
						} catch (err) {
							reject(err)
						}
					})

					await cacheDataLoaded
					await socketConnected

					socket.send(
						JSON.stringify({
							type: WS_MESSAGE_TYPE.SUBSCRIBE,
							destination,
							body: {
								...parseWsBody(arg),
								locale: 'en_US',
							},
						}),
					)

					const sendPong = () => {
						socket.send(
							JSON.stringify({
								type: WS_MESSAGE_TYPE.PONG,
								destination: 'ALL',
							}),
						)
					}

					const listener = (event) => {
						const data = JSON.parse(event?.data || {})
						const { odds } = data

						const { type: messageType } = data

						switch (messageType) {
							case WS_MESSAGE_TYPE.PING: {
								sendPong()
								break
							}
							case WS_MESSAGE_TYPE.FULL_ODDS:
							case WS_MESSAGE_TYPE.UPDATE_ODDS: {
								updateCachedData(() => {
									return odds
								})
								break
							}

							default:
								break
						}
					}
					socket.addEventListener('message', listener)
				} catch {
					// TODO update handle error function.
					socket?.close()

					if (isFunction(arg.callbackRetryFunc)) {
						arg.callbackRetryFunc()
					}
				}
				await cacheEntryRemoved

				socket?.close()
			},
			transformResponse(response) {
				if (response) {
					return response
				}
				return {}
			},
			keepUnusedDataFor: 0,
		}),
		getPeriodList: builders.query({
			query: () => ({
				endpoint: ODDS_PERIODS,
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					return response || []
				}
				return []
			},
		}),
		getOutRight: builders.query({
			async queryFn(
				_arg,
				{ getState, dispatch },
				_extraOptions,
				sportsServicesBQ,
			) {
				try {
					const { betIds = '', locale, sportId, ot, eSportCode = '' } = _arg
					let outright = null
					const { data: cached = [] } = await dispatch(
						sportServices.endpoints.getOutRight.initiate(
							{ locale, sportId, ot, betIds },
							{ subscribe: false, forceRefetch: false },
						),
					)
					const sportParams = {
						_g: getState().User.isLoggedIn ? 0 : 1,
						betIds,
						sportId,
						ort: 1,
						ot,
						oddSince: 0,
						mk: 3,
						fixtureSince: 0,
					}
					if (sportId === SPORT_NAME['esports']) {
						outright = await sportsServicesBQ({
							endpoint: COMPACT_ESPORTS_OUTRIGHT,
							method: 'get',
							params: {
								...sportParams,
								eSportCode,
							},
						})
					} else {
						outright = await sportsServicesBQ({
							endpoint: COMPACT_OUTRIGHT,
							method: 'get',
							params: { ...sportParams },
						})
					}
					if (!outright.data) {
						return []
					}
					const { futures } = new Futures(
						outright.data.a,
						getState().Preferences.odds,
						cached,
					)

					const events = [...futures.values()]
					return { data: events }
				} catch (error) {
					console.error(error)
				}
			},
		}),
		getSportMatchup: builders.query({
			query: (params) => ({
				endpoint: ODDS_MATCHUPS,
				params: {
					sportId: params.sportId,
					version: params.version || 0,
					oddsType: params.oddsType,
					timeStamp: params.timeStampRTK,
					language: params.language,
					periodNum: params.periodNum === undefined ? '' : params.periodNum,
				},
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					const resultResponse = parseMatchUpResponse(response)
					return resultResponse
				}
				return {}
			},
			keepUnusedDataFor: 0,
			transformErrorResponse(response, meta, arg) {
				return {
					isError: true,
				}
			},
		}),
		getTeamTotalLive: builders.query({
			query: (params) => ({
				endpoint: ODDS,
				params: {
					btg: 100,
					sportId: params.sportId,
					isLive: params.isLive,
					isHlE: params.isHlE,
					oddsType: params.oddsType,
					version: params.version || 0,
					timeStamp: params.timeStampRTK,
					leagueCode: params.leagueCode,
				},
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					const resultResponse = parseMatchUpResponseTeamTotal(response)
					return resultResponse
				}
				return {}
			},
			keepUnusedDataFor: 0,
			transformErrorResponse(response, meta, arg) {
				return {
					isError: true,
				}
			},
		}),
		getMatchupEsport: builders.query({
			query: (params) => ({
				endpoint: ODDS_MATCHUPS,
				params: {
					sportId: params.sportId,
					oddsType: params.oddsType,
					version: params.version || 0,
					timeStamp: params.timeStampRTK,
					language: params.language,
					eSportCode: params.eSportCode,
					periodNum: params.periodNum || '',
				},
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					const resultResponse = parseMatchUpResponse(response)
					return resultResponse
				}
				return {}
			},
			keepUnusedDataFor: 0,
			transformErrorResponse(response, meta, arg) {
				return {
					isError: true,
				}
			},
		}),
		getMatchupByLeague: builders.query({
			async queryFn(_arg, _queryApi, _extraOptions, sportsServicesBQ) {
				try {
					const { data: cached = [] } = await _queryApi.dispatch(
						sportServices.endpoints.getMatchupByLeague.initiate(
							{ ..._arg },
							{ subscribe: false, forceRefetch: false },
						),
					)
					const response = await sportsServicesBQ({
						endpoint: ODDS_LEAGUE,
						params: { ..._arg },
						method: 'get',
					})
					let specialCategories = get(response, ['data', 'specialCategories'])
					if (specialCategories) {
						const filtered = specialCategories.sort((a, b) => {
							let fa = a.name.toLowerCase(),
								fb = b.name.toLowerCase()

							if (fa < fb) {
								return -1
							}
							if (fa > fb) {
								return 1
							}
							return 0
						})
						specialCategories = [...filtered]
					}
					let result = {}
					if (response.data.leagues || response.data.futures) {
						result = _arg.isAlternateLines
							? response.data
							: parseMatchUpResponse(response.data, true)
					} else {
						result = {
							...result,
							refreshAll: true,
							dateList: [],
							noData: true,
						}
					}

					const specialEvents = get(response, ['data', 'futures'])
					if (specialEvents) {
						const { futures } = new Futures(
							specialEvents,
							_queryApi.getState().Preferences.odds,
							cached,
							'euro',
							response.data.leagueId,
						)

						const events = [...futures.values()]

						return {
							data: {
								...result,
								specialEvents: events,
								specialCategories: specialCategories,
							},
						}
					}
					return {
						data: { ...result, specialCategories: specialCategories },
					}
				} catch (e) {
					console.warn('warn: ', e)
					return {
						isError: true,
						error: e.error,
					}
				}
			},
			keepUnusedDataFor: 0,
		}),
		getMatchupByParticipant: builders.query({
			async queryFn(_arg, _queryApi, _extraOptions, sportsServicesBQ) {
				try {
					const { data: cached = [] } = await _queryApi.dispatch(
						sportServices.endpoints.getMatchupByParticipant.initiate(
							{ ..._arg },
							{ subscribe: false, forceRefetch: false },
						),
					)
					const response = await sportsServicesBQ({
						endpoint: PARTICIPANT_MATCHUP,
						params: { ..._arg },
						method: 'get',
					})
					let specialCategories = get(response, ['data', 'specialCategories'])
					if (specialCategories) {
						const filtered = specialCategories.sort((a, b) => {
							let fa = a.name.toLowerCase(),
								fb = b.name.toLowerCase()

							if (fa < fb) {
								return -1
							}
							if (fa > fb) {
								return 1
							}
							return 0
						})
						specialCategories = [...filtered]
					}
					let result = {}
					if (response.data.leagues || response.data.futures) {
						result = _arg.isAlternateLines
							? response.data
							: parseMatchUpResponse(response.data, true)
					} else {
						result = {
							...result,
							refreshAll: true,
							dateList: [],
							noData: true,
						}
					}

					const specialEvents = get(response, ['data', 'futures'])
					if (specialEvents) {
						const { futures } = new Futures(
							specialEvents,
							_queryApi.getState().Preferences.odds,
							cached,
							'euro',
							response.data.leagueId,
						)

						const events = [...futures.values()]

						return {
							data: {
								...result,
								specialEvents: events,
								specialCategories: specialCategories,
							},
						}
					}
					return {
						data: { ...result, specialCategories: specialCategories },
					}
				} catch (e) {
					console.warn('warn: ', e)
					return {
						isError: true,
						error: e.error,
					}
				}
			},
			keepUnusedDataFor: 100000,
		}),
		getTeamTotalByParticipant: builders.query({
			async queryFn(_arg, _queryApi, _extraOptions, sportsServicesBQ) {
				try {
					const response = await sportsServicesBQ({
						endpoint: PARTICIPANT_MATCHUP,
						params: { ..._arg },
						method: 'get',
					})

					const result = parseMatchUpResponseTeamTotal({
						...response.data,
						noData: !response.data.leagues,
					})

					return {
						data: { ...result },
					}
				} catch (e) {
					console.warn('warn: ', e)
					return {
						isError: true,
						error: e.error,
					}
				}
			},
			keepUnusedDataFor: 10000,
		}),
		getTeamTotalLiveByParticipant: builders.query({
			query: (params) => ({
				endpoint: ODDS,
				params: {
					btg: 100,
					sportId: params.sportId,
					isLive: params.isLive,
					isHlE: params.isHlE,
					oddsType: params.oddsType,
					version: params.version || 0,
					timeStamp: params.timeStampRTK,
					leagueCode: params.leagueCode,
					participant: params.participant ? params.participant : '',
				},
				method: 'get',
			}),
			transformResponse(response) {
				if (response) {
					const resultResponse = parseMatchUpResponseTeamTotal(response)
					return resultResponse
				}
				return {}
			},
			keepUnusedDataFor: 0,
			transformErrorResponse(response, meta, arg) {
				return {
					isError: true,
				}
			},
		}),
		getTeamTotalByLeague: builders.query({
			async queryFn(_arg, _queryApi, _extraOptions, sportsServicesBQ) {
				try {
					const response = await sportsServicesBQ({
						endpoint: ODDS_LEAGUE,
						params: { ..._arg },
						method: 'get',
					})

					const result = parseMatchUpResponseTeamTotal({
						...response.data,
						noData: !response.data.leagues,
					})
					let specialCategories = get(
						response,
						['data', 'specialCategories'],
						[],
					)
					specialCategories = sortBy(specialCategories, ['name'])

					return {
						data: { ...result, specialCategories },
					}
				} catch (e) {
					console.warn('warn: ', e)
					return {
						isError: true,
						error: e.error,
					}
				}
			},
			keepUnusedDataFor: 10000,
		}),
		searchByString: builders.query({
			async queryFn(_arg, _queryApi, _extraOptions, searchServiceBQ) {
				try {
					const response = await searchServiceBQ({
						endpoint: SEARCH_BY_STRING,
						params: { ..._arg },
						method: 'get',
					})

					return {
						data: { ...response.data },
					}
				} catch (e) {
					console.warn('warn: ', e)
					return {
						isError: true,
						error: e.error,
					}
				}
			},
			keepUnusedDataFor: 0,
		}),
		getSideBarLiveEvent: builders.query({
			async queryFn(
				{ sportId, oddsType, locale },
				{ getState, signal },
				extraOptions,
				baseQuery,
			) {
				try {
					const { Multiview } = getState()
					const storedSideBarLiveEvent = cloneDeep(
						omit(Multiview, [
							EVENT_DETAIL_IDS_KEY,
							EVENT_ESPORT_HUB_DETAIL_IDS_KEY,
							'eventDetail',
							'sportIds',
						]),
					)
					if (isStandardEsportsHubMultiviewPath()) {
						return processSideBarLiveEventEsportsHubResponse({
							storedSideBarLiveEvent,
							locale,
							oddsType,
							sportId,
							baseQuery,
							endpoint: ODDS,
						})
					}
					return processSideBarLiveEventResponse({
						storedSideBarLiveEvent,
						locale,
						oddsType,
						sportId,
						baseQuery,
						endpoint: ODDS,
					})
				} catch (error) {
					console.warn(error)
				}
			},
			keepUnusedDataFor: 0,
		}),
	}),
})

export const {
	useGetSportLeftMenuQuery,
	useGetSportLeagueLeftMenuQuery,
	useGetEsportGamesLeftMenuQuery,
	useGetSportLeaguesQuery,
	useGetSportLeagueByCodeQuery,
	useGetEsportGamesQuery,
	useGetEsportLeaguesByGameCodeQuery,
	useGetEsportGameByCodeQuery,
	useGetEventDetailQuery,
	useGetEventNewEuroViewQuery,
	useLazyGetPeriodListQuery,
	useGetSportMatchupQuery,
	useGetOutRightQuery,
	useGetMatchupEsportQuery,
	useGetMatchupByLeagueQuery,
	useGetTeamTotalByLeagueQuery,
	useGetTeamTotalLiveQuery,
	useGetFavouriteLeftMenuQuery,
	useSearchByStringQuery,
	useGetMatchupByParticipantQuery,
	useGetTeamTotalByParticipantQuery,
	useGetTeamTotalLiveByParticipantQuery,
	useGetEventNewEuroViewWebSocketQuery,
	useGetMultiviewEventDetailQuery,
	useGetSideBarLiveEventQuery,
	useGetEventLiveQuery,
} = sportApiQuery

export const esportsHubApiQuery = sportServices.injectEndpoints({
	endpoints: (builders) => ({
		getGamesLeftMenu: builders.query({
			query: () => ({
				endpoint: 'esport-hub/games',
				method: 'get',
			}),
			transformResponse(response) {
				return createEsportsHubGames(response)
			},
		}),

		getEsportLeagues: builders.query({
			query: ({ sportId, gameCode }) => ({
				endpoint: `euro/leagues?sportId=${sportId}&gameCode=${gameCode}`,
				method: 'get',
			}),
			transformResponse(response, _, { gameCode }) {
				return createListLeaguesByGames(response, gameCode)
			},
		}),
	}),
})

export const { useGetGamesLeftMenuQuery, useGetEsportLeaguesQuery } =
	esportsHubApiQuery

export const getSportLeftMenu =
	sportApiQuery?.endpoints?.getSportLeftMenu?.initiate()

export const getEventNewEuroView = (params) =>
	sportApiQuery?.endpoints?.getEventNewEuroView?.initiate(params)
