import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { sportApiQuery, esportsHubApiQuery } from '../RTK/sportServicesRTK'

const initialState = {
	options: {
		oddsFormat: 1,
	},
	sport: {},
	league: {},
	game: {},
	azSportsIds: [],
	topSportsIds: [],
	isLoadingSport: false,
	isErrorSport: false,
}
export const Sport = createSlice({
	name: 'Sport',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				isAnyOf(sportApiQuery?.endpoints?.getSportLeftMenu?.matchFulfilled),
				(state, { payload }) => {
					state.sport = payload?.sport
					state.azSportsIds = payload?.azSportsIds
					state.topSportsIds = payload?.topSportsIds
					state.isLoadingSport = false
					state.isErrorSport = false
				},
			)
			.addMatcher(
				isAnyOf(sportApiQuery?.endpoints?.getSportLeftMenu?.matchPending),
				(state) => {
					state.isLoadingSport = true
					state.isErrorSport = false
				},
			)
			.addMatcher(
				isAnyOf(sportApiQuery?.endpoints?.getSportLeftMenu?.matchRejected),
				(state) => {
					state.isLoadingSport = false
					state.isErrorSport = true
				},
			)
			.addMatcher(
				isAnyOf(sportApiQuery?.endpoints?.getEsportGameByCode?.matchFulfilled),
				(state, { payload }) => {
					state.game = {
						...state.game,
						[payload?.gameCode]: {
							...payload,
						},
					}
				},
			)
			.addMatcher(
				isAnyOf(sportApiQuery?.endpoints?.getSportLeagueByCode?.matchFulfilled),
				(state, { payload }) => {
					state.league = {
						...state.league,
						[payload?.leagueCode]: {
							...payload,
						},
					}
				},
			)
			.addMatcher(
				isAnyOf(
					sportApiQuery?.endpoints?.getEsportGamesLeftMenu?.matchFulfilled,
				),
				(state, { payload }) => {
					state.game = payload.game
				},
			)
			.addMatcher(
				isAnyOf(
					esportsHubApiQuery?.endpoints?.getGamesLeftMenu?.matchFulfilled,
				),
				(state, { payload }) => {
					state.esportsGames = payload?.games
				},
			)
	},
})

export default Sport.reducer
