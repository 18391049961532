import storage from 'redux-persist/lib/storage'
import {
	EVENT_DETAIL_IDS_KEY,
	EVENT_ESPORT_HUB_DETAIL_IDS_KEY,
} from 'constants'

export const User = {
	key: 'User',
	keyPrefix: '_',
	storage,
	whitelist: [
		'isLoggedIn',
		'enabledPersonalMessage',
		'enableQuickBet',
		'enableQuickBetNotification',
	],
}

export const Betslip = {
	key: 'Betslip',
	keyPrefix: '_',
	storage,
	whitelist: ['selections', 'bets'],
}

export const Multiview = {
	key: 'Multiview',
	keyPrefix: '_',
	storage,
	whitelist: [
		EVENT_DETAIL_IDS_KEY,
		EVENT_ESPORT_HUB_DETAIL_IDS_KEY,
		'eventDetail',
	],
}
