import { configureStore } from '@reduxjs/toolkit'
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist'

/** *** Slice */
import Layout from './slices/Layout'
import Preferences from './slices/Preferences'
import User from './slices/User'
import Sport from './slices/Sport'
import EventDetail from './slices/EventDetail'
import FavouriteSports from './slices/FavouriteSports'
import OddPage from './slices/OddPage'
import Betslip from './slices/Betslip'
import Matchup from './slices/Matchup'
import Leagues from './slices/Leagues'
import Caches from './slices/Caches'
import Multiview from './slices/Multiview'
import Modal from './slices/Modal'

/** *** Persist */
import {
	User as _User,
	Betslip as _Betslip,
	Multiview as _Multiview,
} from './storagePersist'

/** *** RTK */
import { MEMBER_PATH, memberServices } from './RTK/memberServicesRTK.js'
import { SPORT_PATH, sportServices } from './RTK/sportServicesRTK'
import { LOCAL_PATH, localServices } from './RTK/localServicesRTK'
import { OTHER_PATH, otherApi } from './RTK/otherServicesRTK'
import {
	BET_PLACEMENT_PATH,
	betPlacementServices,
} from './RTK/betPlacementServicesRTK'

const store = configureStore({
	reducer: {
		Layout,
		Preferences,
		User: persistReducer(_User, User),
		Sport,
		EventDetail,
		FavouriteSports,
		OddPage,
		Betslip: persistReducer(_Betslip, Betslip),
		Matchup,
		Leagues,
		Caches,
		Multiview: persistReducer(_Multiview, Multiview),
		Modal,
		[LOCAL_PATH]: localServices.reducer,
		[SPORT_PATH]: sportServices.reducer,
		[MEMBER_PATH]: memberServices.reducer,
		[OTHER_PATH]: otherApi.reducer,
		[BET_PLACEMENT_PATH]: betPlacementServices.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat([
			localServices.middleware,
			sportServices.middleware,
			memberServices.middleware,
			betPlacementServices.middleware,
			otherApi.middleware,
		]),
})

export default store

export const persistor = persistStore(store)
