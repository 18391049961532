import {
	BET_TYPE,
	ODDS_FORMAT,
	WIN_RISK_TYPE,
	TENNIS_ID,
} from '../../constants/common'
import { RESULTING_UNIT } from '../../constants/market'
import { formatSeparators } from 'services/Common'

export const isFloat = (value) =>
	typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value)

export const parseNumberThousandsSeparator = (formattedNumber) => {
	return formattedNumber.toString().replace(/,/g, '') || 0
}

export const formatHandicapNumber = (betType, handicap) => {
	const isInValidType =
		typeof handicap === 'string' ||
		typeof handicap === 'undefined' ||
		handicap === null
	if (isInValidType) {
		return ''
	}
	// do not show hdp point if betType is outright and hdp point is 0
	if (
		(betType === BET_TYPE.OUTRIGHT ||
			betType === BET_TYPE.SPECIALS ||
			betType === BET_TYPE.THE_1X2) &&
		handicap === 0
	) {
		return ''
	}
	const handicapScores = isFloat(handicap)
		? handicap
		: Number.parseFloat(handicap).toFixed(1)

	if (
		betType !== BET_TYPE.OUTRIGHT &&
		betType !== BET_TYPE.SPECIALS &&
		betType !== BET_TYPE.OU &&
		betType !== BET_TYPE.HOME_TOTALS &&
		betType !== BET_TYPE.AWAY_TOTALS
	) {
		return handicap > 0 ? `+${handicapScores}` : `${handicapScores}`
	}
	return `${handicapScores}`
}

export const cacheObjectInLocalStorage = (obj, objName) => {
	if (Object.keys(obj).length > 0) {
		// Todo: Have need endcode obj?
		localStorage.setItem(objName, JSON.stringify(obj))
	}
}

export const getWinRiskOptionByOdds = (odds) => {
	if (odds > 0) {
		return WIN_RISK_TYPE.STAKE
	}
	return WIN_RISK_TYPE.WIN
}

export const getDefaultWinRiskOption = (oddsFormat, odds) => {
	let winRiskOption = ''
	if (oddsFormat === ODDS_FORMAT.AM || oddsFormat === ODDS_FORMAT.EU) {
		// default winRiskOptions is Risk for AM odds and EU odds
		winRiskOption = WIN_RISK_TYPE.RISK
	} else {
		winRiskOption = getWinRiskOptionByOdds(odds)
	}
	return winRiskOption
}

export const convertToNumber = (value) => {
	if (value) {
		value = value.toString().split(',').join('')
	}
	return !isNaN(value) ? Number(value) : value
}

export const calculateWin = (stake, odds, oddsFormat, wro, betType) => {
	if (!odds) {
		return 0
	}
	const riskVal = convertToNumber(stake)

	const winVal = calculateToWin(riskVal, odds, oddsFormat, wro, betType)

	return winVal
}

export const calculateRisk = (stake, odds, oddsFormat, wro) => {
	if (!odds) {
		return 0
	}
	const winVal = convertToNumber(stake)
	const riskVal = calculateToRisk(winVal, odds, oddsFormat, wro)
	return riskVal
}

/**
 * calculate Win from stake
 * @param {*} stake
 * @param {*} odds
 * @param {*} ot : odds type
 * @param {*} wrs : win risk stake (WIN, RISK, BASE)
 */
export const calculateToWin = (stake, odds, ot, wrs, betType) => {
	let win = 0
	ot =
		betType === BET_TYPE.THE_1X2 &&
		(ot === ODDS_FORMAT.HK || ot === ODDS_FORMAT.MY || ot === ODDS_FORMAT.ID)
			? ODDS_FORMAT.EU
			: ot
	if (wrs === WIN_RISK_TYPE.WIN) {
		win = stake
	} else if (wrs === WIN_RISK_TYPE.RISK) {
		win = calculateToWinFromRisk(stake, odds, ot)
	} else {
		if (odds > 0) {
			win = calculateToWinFromRisk(stake, odds, ot)
		} else {
			win = stake
		}
	}
	return parseFloat(win)
}

/**
 * calculate Risk from stake
 * @param {*} stake
 * @param {*} odds
 * @param {*} ot : odds type
 * @param {*} wrs : win risk stake (WIN, RISK, BASE)
 */
export const calculateToRisk = (stake, odds, ot, wrs) => {
	let risk = 0
	if (wrs === WIN_RISK_TYPE.WIN) {
		risk = calculateToRiskFromWin(stake, odds, ot)
	} else if (wrs === WIN_RISK_TYPE.RISK) {
		risk = stake
	} else {
		if (odds > 0) {
			risk = stake
		} else {
			risk = calculateToRiskFromWin(stake, odds, ot)
		}
	}
	return parseFloat(risk)
}

/**
 * Calculate Win from Risk
 * @param {*} riskAmount
 * @param {*} odds
 * @param {*} ot : odds type
 */
export const calculateToWinFromRisk = (riskAmountStr = 0, oddsStr, ot) => {
	let win = 0
	const riskAmount = Number(`${riskAmountStr}`.replaceAll(',', ''))
	const odds = Number(`${oddsStr}`.replaceAll(',', ''))
	switch (Number(ot)) {
		case ODDS_FORMAT.American:
			if (odds === 0) {
				win = 0
			} else if (odds > 0) {
				win = (riskAmount / 100) * odds
			} else if (odds < 0) {
				win = (riskAmount / Math.abs(odds)) * 100
			}
			break
		case ODDS_FORMAT.Decimal:
			win = riskAmount * (odds - 1)
			break
		case ODDS_FORMAT.HongKong:
			win = riskAmount * odds
			break
		case ODDS_FORMAT.Indonesian:
			win =
				odds === 0
					? 0
					: odds > 0
					? riskAmount * odds
					: riskAmount / Math.abs(odds)
			break
		case ODDS_FORMAT.Malay:
			win =
				odds === 0
					? 0
					: odds > 0
					? riskAmount * odds
					: riskAmount / Math.abs(odds)
			break
		default:
			win = 0
	}
	return win
}

/**
 * Calculate Risk from Win
 * @param {*} winAmount
 * @param {*} odds
 * @param {*} ot : odds type
 */
export const calculateToRiskFromWin = (winAmountStr, oddsStr, ot) => {
	let risk = 0
	const winAmount = Number(`${winAmountStr}`.replaceAll(',', ''))
	const odds = Number(`${oddsStr}`.replaceAll(',', ''))
	switch (Number(ot)) {
		case ODDS_FORMAT.American:
			if (odds === 0) {
				risk = 0
			} else if (odds > 0) {
				risk = (winAmount / odds) * 100
			} else if (odds < 0) {
				risk = (winAmount / 100) * Math.abs(odds)
			}
			break
		case ODDS_FORMAT.Decimal:
			risk = winAmount / (odds - 1)
			break
		case ODDS_FORMAT.HongKong:
			risk = winAmount / odds
			break
		case ODDS_FORMAT.Indonesian:
			risk =
				odds === 0
					? 0
					: odds > 0
					? winAmount / odds
					: winAmount * Math.abs(odds)
			break
		case ODDS_FORMAT.Malay:
			risk =
				odds === 0
					? 0
					: odds > 0
					? winAmount / odds
					: winAmount * Math.abs(odds)
			break
		default:
			risk = 0
	}
	return risk
}

export const isCreateSuffixName = ({ originalHomeTeam, betType, handicap }) => {
	return (
		['Over', 'Under'].includes(originalHomeTeam) &&
		betType === BET_TYPE.OUTRIGHT &&
		handicap
	)
}

export const getFormattedGradingUnit = ({
	sportId,
	gradingUnit,
	originalHomeTeam,
	unit,
	eventName,
	betType,
	formatMessage,
	isLive,
	handicap = '',
}) => {
	let result = ' '
	if (parseInt(sportId) === TENNIS_ID && gradingUnit) {
		if (
			eventName &&
			(!eventName.includes(gradingUnit) || betType === BET_TYPE.OU)
		) {
			const splitEventName = eventName.trim().split(' ')
			const isExistGameName = isLive && splitEventName.includes('Game')
			if (gradingUnit !== RESULTING_UNIT.REGULAR) {
				result = `${gradingUnit}`
			} else if (
				betType === BET_TYPE.THE_1X2 &&
				(!isExistGameName || !isLive)
			) {
				result = `${RESULTING_UNIT.SETS}`
			} else {
				result = `${RESULTING_UNIT.GAMES}`
			}
		}
	}

	if (isCreateSuffixName({ originalHomeTeam, betType, handicap })) {
		return `${handicap} ${unit}`
	}

	return formatMessage({
		id: `event_detail.${result?.toLowerCase()}`,
		defaultMessage: result,
	})
}

export const roundRobinCountLabel = (groupedBets) => {
	const groups = groupedBets.map((bet) => bet?.wagerSelections.length, [])

	const labels = groups.filter(function (item, pos, self) {
		return self.indexOf(item) === pos
	})

	return labels
		.reduce((acc, key) => {
			let count = 0
			groups.forEach((item) => {
				if (item === key) {
					count++
				}
			})
			return [...acc, { [key]: count }]
		}, [])
		.reduce((acc, item, idx) => {
			const key = Object.keys(item)[0]
			return idx < labels.length - 1
				? `${key}'s x ${acc} ${item[key]},`
				: `${key}'s x ${acc} ${item[key]}`
		}, '')
}

export const formatSelectionHandicap = (betType, handicap) => {
	let content = ''
	if (typeof handicap === 'number') {
		if (betType === BET_TYPE.OU) {
			content = formatSeparators(handicap)
		} else if (betType === BET_TYPE.HANDICAP) {
			content = formatHandicapNumber(BET_TYPE.HANDICAP, handicap)
		} else if (
			betType === BET_TYPE.HOME_TOTALS ||
			betType === BET_TYPE.AWAY_TOTALS
		) {
			content = handicap
		}
	}
	return content
}
