export const EVENT_DETAIL_API_ACTION = {
	INIT: 'INIT',
	NONE: 'NONE',
	UPDATE_ALL: 'UPDATE_ALL',
	UPDATE_TABS: 'UPDATE_TABS',
	UPDATE_ODDS: 'UPDATE_ODDS',
	END_EVENT: 'END_EVENT',
}

export const ODD_STATUS = {
	NONE: 'NONE',
	UP: 'UP',
	DOWN: 'DOWN',
	OFFLINE: 'OFFLINE',
}

export const ODD_TYPE = {
	NORMAL: 'NORMAL',
	SPECIAL: 'SPECIAL',
}

export const SIDE_BAR_LIVE_EVENT_API_ACTION = {
	INIT: 'INIT',
	NONE: 'NONE',
	UPDATE_ALL: 'UPDATE_ALL',
	UPDATE_LEAGUE: 'UPDATE_LEAGUE',
	UPDATE_EVENT: 'UPDATE_EVENT',
}

export const SHORT_NAME = {
	FIRST: '1',
	SECOND: '2',
}

export const EVENT_DETAIL_IDS_KEY = 'eventDetailIds'

export const EVENT_ESPORT_HUB_DETAIL_IDS_KEY = 'eventEsportsHubDetailIds'

export const DRAG_HINT_ID = -1

export const LIVE_DETAIL_EVENT_CONTAINER = 'live-detail-event-container'
