import {
	LOGIN_STATUS,
	ODDS_FORMAT_ID,
	OVERVIEW_PINNED_KEY,
	VIEWS,
} from 'constants/common'
import { getAllCookies, getCookie, removeCookie } from 'services/Cookie'
import isEmpty from 'lodash/isEmpty'
import { preferences as preferencesMap } from 'constants/cookies'
import store from 'stores/main'
import {
	resetSession,
	setDefaultStake,
	setEnableMaintenance,
	setUser,
} from 'stores/slices/User'
import { setLanguageCode, setOddType } from 'stores/slices/Preferences'
import localeInstance from 'apps/types/Locale'
import {
	getHeaderXAppData,
	headerToObject,
	removeHeaderTokens,
	removeHeaderXAppData,
} from 'services/LocalStorage'
import { triggerCloseMiniPlayer } from 'services/LiveStream'
import { buildRouteUrl, getCurrentRouterProvider } from 'services/Url'
import { routesAnotherViews } from 'services/Routes/routes'
import { matchPath } from 'react-router'

const COOKIES_IGNORE = ['_partner']

class Session {
	static transformTrustCodeToStorage(loginId = '') {
		const trustCode = getCookie('trustCode') ?? ''
		if (!loginId || !trustCode) {
			return false
		}
		const _trustCode = JSON.parse(localStorage.getItem('trustCode')) || {}
		_trustCode[loginId] = trustCode
		localStorage.setItem('trustCode', JSON.stringify(_trustCode))
	}

	static catchLoginError(result) {
		if (result.error) {
			return {
				code: 'SERVICE_ERROR',
				rest: null,
			}
		}
		switch (result.data) {
			case LOGIN_STATUS.INVALID_CAPTCHA:
				return {
					code: 'INVALID_CAPTCHA',
					rest: null,
				}
			case LOGIN_STATUS.INVALID_USER:
				return {
					code: 'INVALID_USER',
					rest: null,
				}
			case LOGIN_STATUS.WRONG_COUNTRY:
				return {
					code: 'WRONG_COUNTRY',
					rest: null,
				}
			case LOGIN_STATUS.LOGIN_FAILED:
				return {
					code: 'LOGIN_FAILED',
					rest: null,
				}
			default:
				if (result.data.error === 'M004') {
					return {
						code: 'M004',
						rest: { expire: result.data.expire },
					}
				}
				return null
		}
	}

	static logout() {
		if (window.env?.enableEsportsLiveStreamModule) {
			triggerCloseMiniPlayer()
		}
		localStorage.removeItem(OVERVIEW_PINNED_KEY)
		if (window.env?.headerBasedEnabled) {
			removeHeaderTokens()
			removeHeaderXAppData()
		} else {
			const cookies = getAllCookies() ?? {}
			for (const [key] of Object.entries(cookies)) {
				if (COOKIES_IGNORE.includes(key?.toLowerCase())) {
					continue
				}
				removeCookie(key)
			}
		}
	}

	static getPreferences() {
		let preferences = window.env.headerBasedEnabled
			? headerToObject(getHeaderXAppData())?.__prefs
			: getCookie('__prefs')
		if (isEmpty(preferences)) return {}
		preferences = JSON.parse(window.atob(decodeURIComponent(preferences)))
		return Object.keys(preferencesMap).reduce((value, item, index) => {
			value[item] = preferences[index]
			return value
		}, {})
	}

	static syncConfigFromPreference() {
		const preferences = this.getPreferences()
		store.dispatch(setOddType(ODDS_FORMAT_ID[preferences?.oddsFormat]))
		store.dispatch(
			setDefaultStake(
				preferences?.enableDefaultStake ? preferences?.defaultStake || 0 : 0,
			),
		)
	}

	static processDataNocache(dataNoCache) {
		if (dataNoCache.auth) {
			this.updateLanguage(dataNoCache?.locale)
			store.dispatch(setUser(dataNoCache))
			this.syncConfigFromPreference()
		} else {
			store.dispatch(resetSession())
			store.dispatch(setEnableMaintenance(dataNoCache.enableMaintenance))
		}
	}

	static updateLanguage(newLanguage) {
		localeInstance.setLanguage(newLanguage)
		store.dispatch(setLanguageCode(newLanguage))
	}

	static checkAndRedirectToDefaultView(userDefaultView = '') {
		const { routes } = getCurrentRouterProvider()
		if (
			!userDefaultView ||
			userDefaultView === VIEWS.FUTURE ||
			matchPath(
				{ path: routes.liveCentre, end: false },
				window.location.pathname,
			)
		) {
			return
		}
		window.location.href = `${window.location.origin}${buildRouteUrl(
			routesAnotherViews[userDefaultView],
			{},
			true,
		)}`
	}
}

export default Session
