import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loginModal: {
		isOpen: false,
		isFavouriteLogin: false,
		isShowBanner: false,
		username: '',
		password: '',
		onClose: null,
	},
}

export const Modal = createSlice({
	name: 'Modal',
	initialState,
	reducers: {
		openLoginModal: (state, { payload }) => {
			state.loginModal.isOpen = true
			state.loginModal.isFavouriteLogin = !!payload.isFavouriteLogin
			state.loginModal.isShowBanner = !!payload.isShowBanner
			state.loginModal.username = payload.username
			state.loginModal.password = payload.password
			state.loginModal.onClose = payload.onClose
		},
		closeLoginModal: (state) => {
			state.loginModal.isOpen = false
			state.loginModal.isFavouriteLogin = false
			state.loginModal.isShowBanner = false
			state.loginModal.username = ''
			state.loginModal.password = ''
			state.loginModal.onClose = null
		},
	},
})

export const { openLoginModal, closeLoginModal } = Modal.actions

export default Modal.reducer
