import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { sportApiQuery } from '../RTK/sportServicesRTK'
import { getEventsIdsKey, getPrimaryMarketType } from 'services/Multiview'
import {
	DRAG_HINT_ID,
	EVENT_DETAIL_API_ACTION,
	EVENT_DETAIL_IDS_KEY,
	EVENT_ESPORT_HUB_DETAIL_IDS_KEY,
	SIDE_BAR_LIVE_EVENT_API_ACTION,
	SPORT_NAME,
} from 'constants'
import {
	isStandardEsportsHubMultiviewPath,
	isStandardEsportsHubPath,
} from 'services/Url'

const initialEventDetail = {
	pinnedSectionId: {},
	pinnedSectionIds: [],
	bannerData: {},
	lastAction: EVENT_DETAIL_API_ACTION.INIT,
	lastEventId: null,
	lastLocale: null,
	lastOddUpdatedIds: [],
	lastOddsType: null,
	odd: {},
	section: {},
	specialVersion: 0,
	tab: {},
	tabList: [],
	version: 0,
}

const initialState = {
	[EVENT_DETAIL_IDS_KEY]: [DRAG_HINT_ID],
	[EVENT_ESPORT_HUB_DETAIL_IDS_KEY]: [DRAG_HINT_ID],
	eventDetail: { [DRAG_HINT_ID]: { ...initialEventDetail } },
	sportIds: [],
	gameIds: [],
	game: {},
	sport: {},
	event: {},
	league: {},
	leagueIds: [],
	odd: {},
	lastLocale: null,
	lastOddsType: null,
	lastOddUpdatedIds: [],
	lastAction: SIDE_BAR_LIVE_EVENT_API_ACTION.INIT,
	lastSelectedSportId: null,
	selectedSportId: null,
	version: 0,
}

export const Multiview = createSlice({
	name: 'MultiView',
	initialState,
	reducers: {
		updateSelectedSportId: (state, { payload }) => {
			state.selectedSportId = payload?.selectedSportId
		},
		updateSport: (state, { payload }) => {},
		addMultiviewEventDetail: (state, { payload }) => {
			const indexTarget = payload.indexTarget
			const hasIndexTarget = typeof indexTarget === 'number'
			const eventId = payload?.eventId
			const eventIdsKey = getEventsIdsKey()
			const eventIds = state[eventIdsKey] || []
			if (
				!eventId ||
				eventIds.includes(eventId) ||
				(!eventIds.includes(DRAG_HINT_ID) && !hasIndexTarget)
			) {
				return
			}
			let newEventIds = [...eventIds.filter((id) => id !== DRAG_HINT_ID)]
			if (hasIndexTarget) {
				newEventIds.splice(indexTarget, 0, eventId)
				newEventIds.push(DRAG_HINT_ID)
			} else {
				newEventIds.push(eventId, DRAG_HINT_ID)
			}
			newEventIds = newEventIds.slice(0, 4)
			state[eventIdsKey] = [...newEventIds]
			if (!state.eventDetail[payload?.eventId]) {
				state.eventDetail[payload?.eventId] = {
					...initialEventDetail,
					...payload,
				}
			}
		},
		removeMultiviewEventDetail: (state, { payload }) => {
			const eventIdsKey = getEventsIdsKey()
			let newEventIds = state[eventIdsKey].filter(
				(eventDetailId) => eventDetailId !== payload?.eventId,
			)
			if (!newEventIds.includes(DRAG_HINT_ID)) {
				newEventIds = [...newEventIds, DRAG_HINT_ID]
			}
			state[eventIdsKey] = [...newEventIds]
			delete state.eventDetail[payload?.eventId]
		},
		togglePinSection: (state, { payload }) => {
			const eventId = payload?.eventId
			const sectionId = payload?.sectionId
			if (
				!state.eventDetail[eventId] ||
				!state.eventDetail[eventId].section[sectionId]
			)
				return
			const pinnedValue =
				!state.eventDetail[eventId].section[sectionId].isPinned
			state.eventDetail[eventId].section[sectionId].isPinned = pinnedValue
			state.eventDetail[eventId].pinnedSectionIds = state.eventDetail[
				eventId
			].pinnedSectionIds.filter(
				(pinnedSectionId) => pinnedSectionId !== sectionId,
			)
			if (pinnedValue) {
				state.eventDetail[eventId].pinnedSectionIds.unshift(sectionId)
				state.eventDetail[eventId].pinnedSectionId = {
					[sectionId]: 0,
				}
			} else {
				delete state.eventDetail[eventId].pinnedSectionId[sectionId]
			}
			state.eventDetail[eventId].pinnedSectionIds.forEach(
				(sectionId, index) => {
					state.eventDetail[eventId].pinnedSectionId[sectionId] = index
				},
			)
		},
		toggleShowMoreSection: (state, { payload }) => {
			const eventId = payload?.eventId
			const sectionId = payload?.sectionId
			state.eventDetail[eventId].section[sectionId].isSeeMore =
				payload?.isSeeMore
		},
		swapMultiviewEventDetail: (state, { payload }) => {
			const { sourceEventId, targetEventId } = payload
			const eventIdsKey = getEventsIdsKey()
			const eventIds = state[eventIdsKey]
			const sourceIndex = eventIds.indexOf(sourceEventId)
			const targetIndex = eventIds.indexOf(targetEventId)
			if (sourceIndex === DRAG_HINT_ID || targetIndex === DRAG_HINT_ID) return
			const newEventIds = [...eventIds]
			newEventIds[sourceIndex] = targetEventId
			newEventIds[targetIndex] = sourceEventId
			state[eventIdsKey] = [...newEventIds]
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(sportApiQuery?.endpoints?.getSportLeftMenu?.matchFulfilled),
			(state, { payload }) => {
				const liveSportList = Object.values(payload?.sport)
					.filter((sport) => sport?.isLive)
					.sort((a, b) => a.featuresOrder - b.featuresOrder)
				state.sport = liveSportList.reduce((acc, sport) => {
					if (isStandardEsportsHubMultiviewPath()) {
						if (sport?.id !== SPORT_NAME.esports) {
							return acc
						}
					}
					acc[sport?.id] = { ...state.sport[sport?.id], ...sport }
					acc[sport?.id].primaryMarketType = getPrimaryMarketType({
						sport,
					})
					return acc
				}, {})
				state.sportIds = liveSportList.map((sport) => sport?.id)
				if (state.selectedSportId === null && !isStandardEsportsHubPath()) {
					state.selectedSportId = state.sportIds[0]
					state.lastSelectedSportId = state.sportIds[0]
				}
				if (state.selectedSportId === null && isStandardEsportsHubPath()) {
					state.selectedSportId = SPORT_NAME.esports
					state.lastSelectedSportId = SPORT_NAME.esports
				}
			},
		)
		builder.addMatcher(
			isAnyOf(sportApiQuery?.endpoints?.getSideBarLiveEvent?.matchFulfilled),
			(state, { payload }) => {
				if (payload.action === SIDE_BAR_LIVE_EVENT_API_ACTION.NONE) {
					return
				}
				state.event = payload.event
				state.league = payload.league
				state.leagueIds = payload.leagueIds
				state.odd = payload.odd
				state.lastLocale = payload.lastLocale
				state.lastOddsType = payload.lastOddsType
				state.lastOddUpdatedIds = payload.lastOddUpdatedIds
				state.lastAction = payload.action
				state.version = payload.version
				state.lastSelectedSportId = payload.sportId
				state.game = payload.game ? payload.game : {}
				state.gameIds = payload.gameIds ? payload.gameIds : []
				if (state.sport && state.sport[payload.sportId]) {
					state.sport[payload.sportId] = {
						...state.sport[payload.sportId],
						leagueIds: payload.sport[payload.sportId].leagueIds,
					}
				}
			},
		)
		builder.addMatcher(
			isAnyOf(
				sportApiQuery?.endpoints?.getMultiviewEventDetail?.matchFulfilled,
			),
			(state, { payload }) => {
				const eventId = payload?.eventId
				if (state.eventDetail && state.eventDetail?.[payload?.eventId]) {
					state.eventDetail[eventId].tab = payload?.tab
					state.eventDetail[eventId].odd = payload?.odd
					state.eventDetail[eventId].section = payload?.section
					state.eventDetail[eventId].tabList = payload?.tabList
					state.eventDetail[eventId].bannerData = payload?.bannerData
					state.eventDetail[eventId].version = payload?.version
					state.eventDetail[eventId].specialVersion = payload?.specialVersion
					state.eventDetail[eventId].lastOddsType = payload?.lastOddsType
					state.eventDetail[eventId].lastLocale = payload?.lastLocale
					state.eventDetail[eventId].lastEventId = payload?.lastEventId
					state.eventDetail[eventId].lastOddUpdatedIds =
						payload?.lastOddUpdatedIds
					state.eventDetail[eventId].lastAction = payload?.action
				}
			},
		)
	},
})

export const {
	updateSelectedSportId,
	addMultiviewEventDetail,
	removeMultiviewEventDetail,
	togglePinSection,
	toggleShowMoreSection,
	swapMultiviewEventDetail,
} = Multiview.actions

export default Multiview.reducer
